import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { APIService } from 'src/app/services/api.service';
import { ApiUrlConfig, NavigateUrlConfig } from 'src/app/constants/common-apiurls';
import { ControlPropertyService } from 'src/app/services/control.properties.service';
import { Router } from '@angular/router';
import { CommonConstants } from 'src/app/constants/common-constants';
import { TranslationService } from 'src/app/services/translation.service';
import { SessionStorageService } from 'src/app/services/session-storage.service'
import { CommonService } from 'src/app/services/common.service';

@Component({
	selector: 'app-initialize-prepare',
	templateUrl: './initialize-prepare.component.html',
	styleUrls: ['./initialize-prepare.component.scss']
})
export class InitializePrepareComponent implements OnInit {
	preparePageUrl: any;
	urlToken: any;
	authToken: any;
	showLoader: boolean = false;
	resp: any;

	constructor(private router: Router, private authService: AuthService,
		private toastrService: ToastrService, private apiService: APIService,
		private controlPropertyService: ControlPropertyService,
		private translationService: TranslationService,
		private sessionStorageService: SessionStorageService,
		private commonService: CommonService
	) { }

	ngOnInit(): void {
		this.showLoader = true;
		this.preparePageUrl = window.location.href;
		this.urlToken = this.preparePageUrl.substring(this.preparePageUrl.indexOf("?") + 1);
		this.authToken = this.urlToken.substring(0, this.urlToken.length - 1);
		this.sessionStorageService.setIntializePrepareDetails('PreparePageUrl', this.preparePageUrl);
		this.validateToken(this.authToken);
	}

	validateToken(authToken: any) {
		this.authService.validateToken(authToken).subscribe(
			(resp: any) => {
				//console.log('Token api response:', resp);

				if (resp && resp.statusCodeResult == 200) {
					let authToken: any = resp.authToken;
					let envelopeOrTemplateId: any = resp.envelopeId;
					let prepareType: string = resp.prepareType;
					let userID: any = '';
					let userEmail: any = '';
					if (resp.userViewModel) {
						userID = resp.userViewModel.userID;
						userEmail = resp.userViewModel.emailID;
						this.authService.setUserDetails(resp.userViewModel);
					}

					this.authService.setEnvelopeTokenDetails(authToken, prepareType, envelopeOrTemplateId);
					if (resp.translationDetails != null && resp.translationDetails.languageKeyTranslation != null) {
						this.authService.setLanguageKeyTranslations(resp.translationDetails.languageKeyTranslation);
					}

					console.log("sourceUrlType: " + resp.sourceUrlType);
					let originalSource: any = "2";
					if (resp.sourceUrlType && resp.sourceUrlType != "" && resp.sourceUrlType != null && resp.sourceUrlType != "null" && resp.sourceUrlType != undefined && resp.sourceUrlType != " ") originalSource = resp.sourceUrlType;
					//localStorage.setItem('SourceUrlType', originalSource);
					this.sessionStorageService.setIntializePrepareDetails('SourceUrlType', originalSource);
					//localStorage.setItem('prepareType', prepareType);
					this.sessionStorageService.setIntializePrepareDetails('PrepareType', prepareType);
					//call envelope details API
					if (this.controlPropertyService.checkNull(envelopeOrTemplateId, '') != '') {
						let encryptedValue: any = this.commonService.encrypt(envelopeOrTemplateId + "&od=" + originalSource);
						if (prepareType.toLowerCase() === 'envelope') {
							this.router.navigate(['/envelope-prepare'], { queryParams: { id: encryptedValue } },);
						}
						else if (prepareType.toLowerCase() === 'template') {
							this.router.navigate(['/template-prepare'], { queryParams: { id: encryptedValue } });
						}
					}
				}
				else {
					this.showLoader = false;
					// if (resp.authMessage) this.toastrService.warning(resp.authMessage, 'Warning');
					// else this.toastrService.warning(CommonConstants.TokenValidatedMsg, 'Warning');

					this.toastrService.warning(CommonConstants.SessionTimedOutMsg, 'Warning');

					this.sessionStorageService.setIntializePrepareDetails('EnvelopeID', '');
					this.sessionStorageService.setIntializePrepareDetails('EmailId', '');
					this.sessionStorageService.setIntializePrepareDetails('PageName', 'validating token');

					if (resp.sourceUrlType && resp.sourceUrlType != "" && resp.sourceUrlType != null && resp.sourceUrlType != "null"
						&& resp.sourceUrlType != undefined && resp.sourceUrlType != " ") {
						let sourceUrl: any = this.commonService.generateDomainRediRectURL(resp.sourceUrlType);
						window.location.href = sourceUrl + NavigateUrlConfig.LogOn;
					}
					else
						this.router.navigate(['/global-error']);
				}
			})
	}

}



