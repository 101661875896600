import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CommonConstants, NavigateURLS } from '../constants/common-constants';
import { TranslationService } from 'src/app/services/translation.service';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
import { NavigateUrlConfig } from '../constants/common-apiurls';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    commonConstants = CommonConstants;
    ControlListArr: any = this.commonConstants.ControlListArr;
    private previewObjectSubject = new Subject<any>();
    previewObject$ = this.previewObjectSubject.asObservable();
    redirectUrl: any = "";
    //private getDisplayStyleCached: { [key: string]: any } = {};

    private _previewObject: any = {};
    selectedItems: any[] = [];

    private callParentShowControlFieldsSubject = new Subject<{ event: any; data: any; }>();
    callParentShowControlFields$ = this.callParentShowControlFieldsSubject.asObservable();

    private callOnRecipentChangeSubject = new Subject<void>();
    callOnRecipentChange$ = this.callOnRecipentChangeSubject.asObservable();

    private callRemoveControlComponentSubject = new Subject<{ data: any; label: any; }>();
    callRemoveControlComponent$ = this.callRemoveControlComponentSubject.asObservable();

    private callOpenDependencyPopupSubject = new Subject<void>();
    callOpenDependencyPopup$ = this.callOpenDependencyPopupSubject.asObservable();

    private setTextOrNameAreaSettingSubject = new Subject<void>();
    setTextOrNameAreaSetting$ = this.setTextOrNameAreaSettingSubject.asObservable();

    private callOnMouseOverRecipentChangeSubject = new Subject<void>();
    callOnMouseOverRecipentChange$ = this.callOnMouseOverRecipentChangeSubject.asObservable();

    private callOpenCreateDuplicateControlSubject = new Subject<void>();
    callOpenCreateDuplicateControl$ = this.callOpenCreateDuplicateControlSubject.asObservable();

    private callUpdateSignerMultipleControlsSubject = new Subject<void>();
    callUpdateSignerMultipleControl$ = this.callUpdateSignerMultipleControlsSubject.asObservable();

    //right click,
    private selectedRightClickOptionSubject = new Subject<{ data: any; label: any; }>();
    selectedRightClickOption$ = this.selectedRightClickOptionSubject.asObservable();

    private callDependencyPopupSubject = new Subject<void>();
    callDependencyPopupFields$ = this.callDependencyPopupSubject.asObservable();

    private callAddDependentFieldRowSubject = new Subject<void>();
    callAddDependentFields$ = this.callAddDependentFieldRowSubject.asObservable();

    private callMultiDocumentsPreviewSubject = new Subject<void>();
    callMultiDocumentsPreview$ = this.callMultiDocumentsPreviewSubject.asObservable();

    private getLabelTranslationValCached: { [key: string]: any } = {};

    private getImageDispalyValCached: { [key: string]: any } = {};

    private getDisableNavBtnsSubject = new Subject<void>();
    getDisableNavBtns$ = this.getDisableNavBtnsSubject.asObservable();

    private updateHeaderSubject = new Subject<void>();
    updateHeaderSubject$ = this.updateHeaderSubject.asObservable();

    constructor(private translationService: TranslationService, private toastrService: ToastrService) { }

    get previewObject(): any {
        return this._previewObject;
    }

    updatePrevObject(updatedObject: any): void {
        this._previewObject = { ...this._previewObject, ...updatedObject };
        this.previewObjectSubject.next(this._previewObject);
    }

    callDependencyPopupFields(data: any) {
        this.callDependencyPopupSubject.next(data);
    }

    callAddDependentFields(data: any) {
        this.callAddDependentFieldRowSubject.next(data);
    }

    callParentShowControlFields(event: any, data: any) {
        this.callParentShowControlFieldsSubject.next({ event: event, data: data });
    }

    callRemoveControlComponent(data: any, label: any) {
        this.callRemoveControlComponentSubject.next({ data: data, label: label });
    }

    callOpenDependencyPopup(data: any) {
        this.callOpenDependencyPopupSubject.next(data);
    }

    callOpenCreateDuplicateControl(data: any) {
        this.callOpenCreateDuplicateControlSubject.next(data);
    }

    callUpdateSignerMultipleControls() {
        this.callUpdateSignerMultipleControlsSubject.next();
    }

    GenerateMaskedEmail(emailAddr: any) {
        let emailArray = emailAddr.split('@');
        let arrayfirstPartstr = emailArray[0];
        let arraylastPaststr = emailArray[1];

        var firstDigits = arrayfirstPartstr.substring(0, 3);
        var maskedString1 = firstDigits + "***" + "@";

        let lastDotPosition = arraylastPaststr.lastIndexOf('.');
        let firstLastPart = arraylastPaststr.substring(0, lastDotPosition);
        let firstLastPartDigits = firstLastPart.substring(0, 3);
        var maskedString2 = firstLastPartDigits + "***";

        let lastPart = arraylastPaststr.substring(lastDotPosition + 1);
        let maskedString3 = maskedString1 + maskedString2 + "." + lastPart;
        return maskedString3;
    }

    redirectTo(type: any) {
        switch (type) {
            case "Features":
                window.open("https://rsign.com/product/electronic-signature", "_blank");
                break;
            case "CustomerSuccess":
                window.open("https://rsign.com/customer-success", "_blank");
                break;
            case "Webinars":
                window.open("https://rsign.com/webinars", "_blank");
                break;
            case "Videos":
                window.open("https://rsign.com/product-video-playlist", "_blank");
                break;
            case "Apps":
                window.open("https://rsign.com/apps", "_blank");
                break;
            case "Blogs":
                window.open("https://rsign.com/blog", "_blank");
                break;
            case "News":
                window.open("https://rsign.com/news", "_blank");
                break;
            case "ContactUs":
                window.open("https://rsign.com/contact/questions", "_blank");
                break;
            case "Support":
                window.open("https://support.rpost.com/hc/en-us", "_blank");
                break;
        }
    }

    callOnRecipentChange(data: any) {
        this.callOnRecipentChangeSubject.next(data);
    }

    callOnMouseOverRecipentChange(recipentObj: any) {
        this.callOnMouseOverRecipentChangeSubject.next(recipentObj);
    }

    generateUUID() {
        var d = new Date().getTime();
        if (window.performance && typeof window.performance.now === "function") {
            d += performance.now();; //use high-precision timer if available
        }
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    };

    getId() {
        return Math.round(1 + Math.floor(Math.random() * 100000));
    }

    getControlName(ctrlName: any, ctrlClassName: any) {
        if (this.ControlListArr && this.ControlListArr.length) {
            let controlListArrayObj: any[] = this.ControlListArr.filter((c: any) => c && c.ControlClassName && c.ControlClassName.toLowerCase() == ctrlClassName && ctrlClassName.toLowerCase());
            if (controlListArrayObj && controlListArrayObj.length && controlListArrayObj.length > 0) {
                return controlListArrayObj[0].ControlName;
            }
        }
    }

    getControlNameBasedOnGuid(guid: any) {
        let controlListArrayObj = this.ControlListArr.filter((c: any) => c.ControlId.toLowerCase() == guid.toLowerCase());
        if (controlListArrayObj.length > 0) {
            return controlListArrayObj[0].ControlName;
        }
    }

    getGuid(controlName: any, controlClassName: any) {
        let controlListArrayObj = this.ControlListArr.filter((c: any) => c.ControlClassName.toLowerCase() == controlClassName.toLowerCase());
        if (controlListArrayObj.length > 0) {
            return controlListArrayObj[0].ControlId;
        }
        else return "";
    }

    getTranslatedText(controlName: any, resourceKey: any) {
        return controlName;
    }

    getHoverControlText(controlString: any) {
        switch (controlString) {
            case "Signature":
                return "Signature";
            case "Text":
                return "Text";
            case "Name":
                return "Name";
            case "Email":
                return "Email";
            case "Title":
                return "Title";
            case "Company":
                return "Company";
            case "Date":
                return "Date";
            case "Checkbox":
                return "Checkbox";
            case "Initials":
            case "NewInitials":
            case "newInitials":
                return 'Initials'; //lang_initials;
            case "Radio":
                return "Radio";
            case "DropDown":
                return "Dropdown";
            case "DateTimeStamp":
                return 'Date & Time Stamp';
            case "Hyperlink":
                return "Hyperlink";
        }
        return controlString;
    }

    setTextOrNameAreaSetting(data: any) {
        this.setTextOrNameAreaSettingSubject.next(data);
    }

    selectedRightClickOption(data: any, label: any) {
        // console.log('selectedRightClickOption', data, label);
        this.selectedRightClickOptionSubject.next({ data: data, label: label });
    }

    setSelectedItems(selectedItems: any) {
        this.selectedItems = selectedItems;
    }

    getSelectedItems() {
        return this.selectedItems;
    }

    getLabelTranslationVal(resourceKeyId: any) {
        const key = resourceKeyId;
        if (!this.getLabelTranslationValCached[key]) {
            let transVal: any = this.getLabelTranslationValCompute(resourceKeyId);
            if (transVal && transVal != '') {
                this.getLabelTranslationValCached[key] = transVal;
            }
        }
        return this.getLabelTranslationValCached[key];
    }

    private getLabelTranslationValCompute(resourceKeyId: any) {
        return this.translationService.getLanguageTranslationVal(resourceKeyId);
    }

    redirect(url: string): void {
        window.location.href = url;
    }

    openInNewTab(url: string) {
        window.open(url, '_blank');
    }

    getDateFormat(day: any, month: any, year: any, df: any, dmonth: any) {
        var dateRetVal = null;
        switch (df) {
            case 'dd/mm/yyyy':
                dateRetVal = '' + day + '/' + month + '/' + year; // '01/09/2023'
                break;
            case 'mm/dd/yyyy':
                dateRetVal = '' + month + '/' + day + '/' + year; // '01/09/2023'
                break;
            case 'mm-dd-yyyy':
                dateRetVal = '' + month + '-' + day + '-' + year; //'01-09-2023'
                break;
            case 'mm.dd.yyyy':
                dateRetVal = '' + month + '.' + day + '.' + year; //'01.09.2023'
                break;
            case 'dd-mmm-yyyy':
                let monthsArr = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
                dateRetVal = '' + day + '-' + monthsArr[dmonth] + '-' + year;
                break;
            case 'dd-mm-yyyy':
                dateRetVal = '' + day + '-' + month + '-' + year;
                break;
            case 'dd.mm.yyyy':
                dateRetVal = '' + day + '.' + month + '.' + year;
                break;
            case 'yyyy.mm.dd.':
                dateRetVal = '' + year + '.' + month + '.' + day + '.';
                break;
            case 'yyyy/mm/dd':
                dateRetVal = '' + year + '/' + month + '/' + day;
                break;
        }
        return dateRetVal;
    }

    callMultiDocumentsPreview(imgArr: any) {
        this.callMultiDocumentsPreviewSubject.next(imgArr);
    }

    getDisableNavBtns(data: any) {
        this.getDisableNavBtnsSubject.next(data);
    }

    updateHeader() {
        this.updateHeaderSubject.next();
    }

    encrypt(value: any) {
        let appKey = CommonConstants.AppKey;
        var key = CryptoJS.enc.Utf8.parse(appKey);
        var iv = CryptoJS.enc.Utf8.parse(appKey);
        var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
            {
                keySize: 128 / 8,
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });

        return encrypted.toString();
    }

    decrypt(value: any) {
        let appKey = CommonConstants.AppKey;
        var key = CryptoJS.enc.Utf8.parse(appKey);
        var iv = CryptoJS.enc.Utf8.parse(appKey);
        var decrypted = CryptoJS.AES.decrypt(value, key, {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        return decrypted.toString(CryptoJS.enc.Utf8);
    }

    generateDomainRediRectURL(sourceUrlType: any) {
        let redirectUrl: any = "";
        if (sourceUrlType && sourceUrlType != "") {
            if (sourceUrlType == "1") redirectUrl = NavigateURLS.Localhost;
            else if (sourceUrlType == "2") redirectUrl = NavigateURLS.ProdComURL;
            else if (sourceUrlType == "3") redirectUrl = NavigateURLS.ProdOrgURL;
            else if (sourceUrlType == "4") redirectUrl = NavigateURLS.ProdSFURL;
            else if (sourceUrlType == "5") redirectUrl = NavigateURLS.StagingURL;
            else if (sourceUrlType == "6") redirectUrl = NavigateURLS.StagingSFURL;
            else if (sourceUrlType == "7") redirectUrl = NavigateURLS.StagingIOURL;
            else if (sourceUrlType == "8") redirectUrl = NavigateURLS.StagingSFIOURL;
            else if (sourceUrlType == "9") redirectUrl = NavigateURLS.StagingSignURL;
            else if (sourceUrlType == "10") redirectUrl = NavigateURLS.ProdSFOrgURL;
            else redirectUrl = NavigateURLS.ProdComURL;
        }
        else redirectUrl = NavigateURLS.ProdComURL;
        return redirectUrl;
    }

    redirectToMainApp(sourceURL: any) {
        this.redirectUrl = sourceURL;
        console.log("sourceURL:" + sourceURL);
        this.toastrService.info(CommonConstants.SessionTimedOutMsg, 'Attention');
        setTimeout(() => {
            if (this.redirectUrl == "") {
                this.redirectUrl = environment.rsignUrl;
            }
            console.log("redirectUrl:" + this.redirectUrl);
            window.location.href = this.redirectUrl + NavigateUrlConfig.LogOn;
        }, 1500);
    }

    // getImageDisplayStyles(item: any) {
    //     const key = item.id;
    //     if (!this.getImageDispalyValCached[key]) {
    //         let widthVal: any = this.getImageDisplayStylesValCompute(item);
    //         if (widthVal && widthVal != '') {
    //             this.getImageDispalyValCached[key] = widthVal;
    //         }
    //     }
    //     return this.getImageDispalyValCached[key];
    // }

    // private getImageDisplayStylesValCompute(item: any) {
    //     let defaultWidth: any = '915px';
    //     if (item && item.width != null && item.width != '' && item.width != undefined) {
    //         defaultWidth = item.width;
    //     }

    //     return { 'width': defaultWidth};
    // }

    // cashe method
    // getDisplayStyleWidthCached(item: any) {
    //     const key = JSON.stringify(item);

    //     if (!this.getDisplayStyleCached[key]) {
    //         this.getDisplayStyleCached[key] = this.getDisplayStyleCachedCompute(item);
    //     }

    //     return this.getDisplayStyleCached[key];
    // }

    // private getDisplayStyleCachedCompute(item: any) {
    //     let defaultWidth: any = '915px';
	// 	if (item && item.width != null && item.width != '' && item.width != undefined) {
	// 		defaultWidth = item.width;
	// 	}
	// 	return { 'width': defaultWidth };
    // }

}