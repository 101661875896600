<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title font-w600" id="MultipleSelectionGroupEditModel">
            {{getLanguageTranslationValue(resourceKeys.ModifySelectedGroupSettingsTitle)}}</h5>
        <div class="modalposition" aria-label="Close">
            <span class="material-icons model-closeicon" (click)="saveGroupEditModifications('0')">close</span>
        </div>
    </div>
    <div class="modal-body">
        <div class="d-flex align-items-center w-30">
            <div class="md-6" style="float: right;">
                <label for="exampleFormControlInput1"
                    class=" form-label text-nowrap mb-1">{{getLanguageTranslationValue(resourceKeys.Update)}} {{getLanguageTranslationValue(resourceKeys.Signer)}}:</label>
                <select class="form-select controlshadow" aria-label="Default select example" style="width: 220px;"
                    (change)="applySignerCondition($event)">
                    <option value="-1">Select a value</option>
                    <option *ngFor="let recipent of receipentListArr;let i = index;" [value]="recipent.id"> {{
                        recipent.name }} </option>
                </select>
            </div>
            <div style="float: left; margin-left: 155px;">
                <label for="exampleFormControlInput1"
                class=" form-label text-nowrap mb-1">{{getLanguageTranslationValue(resourceKeys.Update)}} {{getLanguageTranslationValue(resourceKeys.Required)}}:</label>
            <select class="form-select controlshadow" aria-label="Default select example" style="width:140px;"
                (change)="applyRequiredCondition($event)">
                <option value="-1">Select a value</option>
                <option value="1">{{getLanguageTranslationValue(resourceKeys.Yes)}}</option>
                <option value="0">{{getLanguageTranslationValue(resourceKeys.No)}}</option>
            </select>
            </div>
        </div>
    </div>
    <div class="modal-footer justify-content-end">
        <button class="btn back-btn cancelbtn form-btn" (click)="saveGroupEditModifications('0')">Cancel</button>
        <button role="button" class="okbtn btn back-btn form-btn ms-3" (click)="saveGroupEditModifications('1')">
            Update</button>
    </div>
</div>