import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ResourceKeys } from 'src/app/constants/resourcekeys';
import { CommonService } from '../../services/common.service';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { ControlPropertyService } from 'src/app/services/control.properties.service';
import { Control } from 'src/app/constants/common-constants';

@Component({
    selector: 'app-multiple-signer-update',
    templateUrl: './multiple-signer-update.component.html',
    styleUrls: ['./multiple-signer-update.component.scss']
})
export class MultipleSignerUpdateComponent {
    @Input() selectedItemsObj: any;
    @Output() multipleSignerUpdateChangesMade: EventEmitter<any> = new EventEmitter();
    resourceKeys = ResourceKeys;
    receipentListArr: any = [];
    receipentColorArr: any = [];
    selectedControlsForGroupEdit: any = [];
    selectedRequiredValue: any = "-1";
    selectedSignerValue: any = "-1";
    activeControlId: any = '';

    constructor(
        public modalRef: BsModalRef, private commonService: CommonService, private authService: AuthService,
        private toastrService: ToastrService, private controlPropertyService: ControlPropertyService,
    ) { }

    ngOnInit(): void {
        let receipentLists: any = this.authService.getRecipientDetails();
        if (receipentLists) {
            this.receipentListArr = JSON.parse(receipentLists);
        }
        let receipentColorArray: any = this.authService.getRecipientColorDetails();
        if (receipentColorArray) {
            this.receipentColorArr = JSON.parse(receipentColorArray);
        }
        this.selectedControlsForGroupEdit = this.selectedItemsObj?.selectedItems;
        this.activeControlId = this.selectedItemsObj?.currentControlId;
    }

    getLanguageTranslationValue(resourcekeyId: any) {
        return this.commonService.getLabelTranslationVal(resourcekeyId);
    }

    applyRequiredCondition(event: any) {
        this.selectedRequiredValue = event.currentTarget.value;
    }

    applySignerCondition(event: any) {
        this.selectedSignerValue = event.currentTarget.value;
    }

    saveGroupEditModifications(type: any) {
        let popUpStatus = { status: type };
        if (type != "0") {
            if ((this.selectedSignerValue == "-1" || this.selectedSignerValue == "") && this.selectedRequiredValue == "-1") {
                this.showWarning('Please select either signer or required option.', 'Warning');
            }
            else {
                Array.from(this.selectedControlsForGroupEdit).forEach((ele: any, index: any) => {
                    let ctrlId: any = ele.replace("resizable_", '');

                    if (this.selectedSignerValue != "-1" && this.selectedSignerValue != "") {
                        let recIndex: any = this.receipentListArr.findIndex((rec: any) => rec.id == this.selectedSignerValue);
                        let recipent: any = { name: this.receipentListArr[recIndex].name, id: this.selectedSignerValue };
                        this.controlPropertyService.applyRecipientDetailsOnMouseOverChange(recipent, this.receipentColorArr[recIndex], ctrlId);

                        if (this.activeControlId == ctrlId) {
                            let recipentObj: any = { recipent: recipent, selectedColor: this.receipentColorArr[recIndex] };
                            this.commonService.callOnMouseOverRecipentChange(recipentObj);
                        }
                    }

                    if (this.selectedRequiredValue != "-1") {
                        var isRequiredText = 'true';
                        if (this.selectedRequiredValue == "0") {
                            isRequiredText = 'false';
                        }
                        let spnCurrentSelectedControl: any = (<HTMLInputElement>document.getElementById("resizable_" + ctrlId));
                        spnCurrentSelectedControl?.setAttribute('data-selected', isRequiredText);
                    }

                    if (this.selectedSignerValue != "-1" && this.selectedSignerValue != "") {
                        this.updateControlDependencyProperties(ctrlId);
                    }
                    else {
                        //check for checkbox required or not. if required remove all dependency
                        let resizableControl: any = (<HTMLInputElement>document.getElementById("resizable_" + ctrlId));
                        let attrGuid: any = resizableControl.getAttribute('data-guid');
                        let data_selected: any = resizableControl.getAttribute('data-selected');
                        if (attrGuid.toLowerCase() === Control.Checkbox.toLowerCase() && data_selected == "true") {
                            this.updateControlDependencyProperties(ctrlId);
                        }
                    }
                });
                this.modalRef.hide();
                this.multipleSignerUpdateChangesMade.next(popUpStatus);
            }
        }
        else {
            this.modalRef.hide();
            this.multipleSignerUpdateChangesMade.next(popUpStatus);
        }
    }

    updateControlDependencyProperties(ctrlId: any) {
        //current selected controls
        let spnCurrentSelectedControl: any = (<HTMLInputElement>document.getElementById(ctrlId));
        let ctrlDocContentId: any = spnCurrentSelectedControl.getAttribute('data-document-content-id');
        this.controlPropertyService.removeAttributes(ctrlDocContentId);
        this.controlPropertyService.removeColorForItem(ctrlId);

        //dependent controls
        let isHavingDependentFieldsArray: any = document.querySelectorAll('[data-parent-id="' + ctrlDocContentId + '"]');
        if (isHavingDependentFieldsArray && isHavingDependentFieldsArray.length > 0) {
            Array.from(isHavingDependentFieldsArray).forEach((childDependentItem: any, ind: any) => {
                this.controlPropertyService.removeColorForItem(childDependentItem.getAttribute('id'));
                this.controlPropertyService.removeAttributes(childDependentItem.getAttribute('data-document-content-id'));
            });
        }
        document.querySelector('[data-document-content-id="' + ctrlDocContentId + '"]')?.setAttribute('is-child-present', '');
    }

    showWarning(value: string, type: string) {
        this.toastrService.warning(value, type);
    }
}
