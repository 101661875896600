<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title font-w600" id="initialsCreation">
            Auto-Place Fields</h5>
        <div class="modalposition" aria-label="Close">
            <span class="material-icons model-closeicon" (click)="modalRef.hide()">close</span>
        </div>
    </div>
    <div class="modal-body">
        <ul style="list-style: none; padding: 0px;margin: 0px;">
            <li style="font-weight: bold;">
                <p>Specify the page range to auto place initial controls on uploaded documents.</p>
            </li>
            <li>
                <input id="placeOnAllPage" type="radio" name="radio" checked (click)="onSelectedOption('Place On All Pages')" />
                <label for="placeOnAllPage" style="padding-left: 10px;top: -2px;position: relative;"> Place on all
                    pages</label>
            </li>
            <li style="display: flex;">
                <input id="placeFromHere" type="radio" name="radio" (click)="onSelectedOption('Place From Here')" />
                <div>
                    <label for="placeFromHere"
                        style="padding-left: 10px;top: -1px;position: relative;padding-right: 10px;"> Place from
                        page</label>
                    <input id="fromPageNumber" type="text" style="width:50px;" autocomplete="off" max="999"
                        (input)="checkRange($event)" />
                    <label for="placeFromHere"
                        style="padding-left: 5px;top: -1px;position: relative;padding-right: 5px;"> to page</label>
                    <input id="toPageNumber" type="text" style="width:50px;" autocomplete="off" max="999"
                        (input)="checkRange($event)" />
                </div>
            </li>
        </ul>
    </div>
    <div class="modal-footer justify-content-end">
        <button class="btn back-btn cancelbtn form-btn" (click)="modalRef.hide()">Cancel</button>
        <button [disabled]="!selectedOption" role="button" class="okbtn btn back-btn form-btn ms-3"
            (click)="createTypeOfInitialsApply()">
            Submit</button>
    </div>
</div>
<div *ngIf="showLoader" class="loader"></div>