export class ResourceKeys {
    public static GeneralSettings: string = "1DFC1FA9-FC98-4ED1-B421-2D1C291BDFB6";
    public static AdvancedSettings: string = "C374F9B7-D23D-4E69-985D-C561B345EADA";
    public static AdminSettings: string = "91886562-AF78-481C-9B80-F3FCE23B53AC";
    public static SystemSettings: string = "117692A5-B579-4D1F-9AD9-C3F34101FCF5";
    public static PrivacySettings: string = "F19EBF72-F694-4565-B008-25CFA06657B4";
    public static DateFormat: string = "DB6ECFE0-ABE6-44E2-AA3B-AE8819F0C5C3";
    public static ExpiresIn: string = "ACE132A6-3F58-4B3B-BE82-691446C0A0B8";
    public static SendFirstReminderIn: string = "120F50FA-FDAB-4D8D-99E3-540E462B3BBF";
    public static ThenSendFollowUpRemindersEvery: string = "363D9101-8682-41FC-8F6B-2DF61BD78984";
    public static SendFinalReminderBeforeExpiration: string = "BF9B7DCC-950E-4A00-8877-38D06770E2E1";
    public static EnvelopeExpirationRemindertoSender: string = "7E70886B-54D8-4BC4-B059-A1FA0593F28A";
    public static lang_Y: string = "D3C56440-6A50-4259-B24E-24BD9A0693BE";
    public static lang_N: string = "A5F19D7F-FB5C-4451-9DB2-F499FE9AC583";
    public static SendReminderTillExpiration: string = "B31655E5-E726-4EB8-81D8-ABBD612110FA";
    public static AccessAuthentication: string = "4FC99518-EF53-44F3-8BB5-A893C574C7DD";
    public static lang_none: string = "23EE52A0-41EB-485C-AEC3-B446768C8F8C";
    public static EmailAccessCode: string = "FE1C80C7-E56B-4EF3-A15A-607E63D9652F";
    public static Unchecked: string = "EA688CD4-86A5-4E82-A6EE-C9C88A15FBEE";
    public static Checked: string = "ED57F769-EA90-473F-9DFE-E87773385026";
    public static IncludeStaticTemplates: string = "77299E86-3ACE-4386-94E5-866986307E40";
    public static RequiresSignersConfirmationonFinalSubmit: string = "34775C2F-431A-4655-A7B4-BA217FDD8A72";
    public static SendIndividualNotifications: string = "c222c3d9-6c2c-4b9c-8e9b-4809c103c40d";
    public static AddSignatureCertificate: string = "700361D4-36E6-434A-8925-87B68710395C";
    public static Sequence: string = "3747D0FE-1021-4BCF-B22A-590779C19799";
    public static DownloadLinkOnManage: string = "AECAE4AC-3DFF-476C-84F7-4EA9160D18E3";
    public static IncludeTransparencyDocument: string = "EA0FF30F-6545-4D0D-A66B-BB96A99BCA4B";
    public static SignerAttachmentOptions: string = "9E6663BD-92E9-4194-8822-4E61AF07FACA";
    public static AdditionalAttachments: string = "A7FF94BC-0836-4AEA-9DAD-F072F866DD00";
    public static IsDefaultSignatureRequiredForStaticTemplate: string = "6021D655-0062-4198-A24F-DFF809C7DE51";
    public static SendConfirmationEmail: string = "06E9C7F1-C1EB-438A-95CE-2ECA5E4D1C11";
    public static SeparateMultipleDocumentsAfterSigning: string = "95266884-7C92-46FC-8A32-6C31D078EF6D";
    public static AttachXMLDocument: string = "4AAE5631-B7A0-4659-95DF-C7FAAB6B680A";
    public static StoreSignatureCertificate: string = "1DA95824-EE7F-4DA5-8E3D-48322B8F2D88";
    public static DeleteOriginalDocument: string = "05166F76-B258-476C-908A-2CC76CACC89E";
    public static StoreOriginalDocument: string = "24EC9EA9-BCD0-40C6-BF3F-F603541AD285";
    public static EnableFileReview: string = "A1921EC5-2708-4163-9B04-518611042F57";
    public static EnableAutoFillTextControls: string = "D300901A-F359-455A-BD7E-F01C575478E1";
    public static FinalContractConversion: string = "B35AC153-4DDE-4E98-91A5-5492E1762AAC";
    public static AttachSignedPdf: string = "11F8B179-0D81-4E2E-B697-580F7AE3199A";
    public static EnableCcOptions: string = "00D85A80-EA48-4A0E-BD36-5F3C699FBB8C";
    public static ElectronicSignatureIndication: string = "BFA01C85-A0F8-4341-AFDC-CCCE660C1D30";
    public static EnableRecipientLanguage: string = "68fd5876-629c-49ed-ac72-0468dfeb0dbf";
    public static EnableSendingMessagesToMobile: string = "C9E845A4-ABF5-4D37-B82B-68C87015308D";
    public static TimeZone: string = "7D5EBF97-49DD-4A70-8D2B-620EE152EDA9";
    public static PrivateMode: string = "1E29E18D-44C0-49F8-A92E-9910E4525927";
    public static DataMasking: string = "D6B591B8-6DE3-4460-B48A-1A7C42FFB764";
    public static DeleteData: string = "ECAA2CD8-358C-4637-80EB-5027140B083F";
    public static CompanySettings: string = "DC9184CC-A094-4768-8E25-F33647007490";
    public static SignatureCapture: string = "E67C9564-988D-4A6E-A4A4-F75C7A06D448";
    public static TypeSignature: string = "72C42EB0-F203-4456-83DB-14EDB767DBDC";
    public static HandDrawn: string = "191DDB81-C976-4381-B40B-B76D42A506BE";
    public static UploadSignature: string = "F09ABA05-C4BB-4383-B382-30AE01F812A4";
    public static HeaderFooterSettings: string = "3A271DB4-C2F1-43A8-81E7-A7EF5BC220B7";
    public static DeleteSignedContracts: string = "6B4027A8-072C-4529-BB82-02315F27CACE";
    public static ReferenceCode: string = "C9CC6C6E-6F8E-4A88-8549-C95BD6EFCA2F";
    public static FormFieldAlignment: string = "A2AB70D9-CBC9-4F21-A110-B46DEC8D4F77";
    public static EnvelopesPostSigningUrl: string = "0162FD3C-957D-410F-97F4-497103BA2A8B";
    public static SetAsDefault: string = "5F1D3C69-7092-4C44-898A-9E77A413524D";
    public static StoreEmailBody: string = "2E3D2D8A-89E7-4C11-A244-E553E66F26E0";
    public static DeleteEmailBody: string = "BFB9B9BF-D468-49C4-BC41-A3D0447F0AE4";
    public static SignatureControlDefaultRequired: string = "DC0D8411-06EB-4F0D-8D1D-F98F2CB6C78A";
    public static SendingConfirmationEmail: string = "13AF4ECE-C831-4E7B-A89D-518D58F0CA8F";
    public static TermsOfService: string = "626C7F9E-B167-424F-B195-43C99C4BCC3E";
    public static TermsServiceOncertificate: string = "D296AC02-7D15-48C8-830E-36BF5B24ED91";
    public static DocumentsCombinedInOneEnvelope: string = "F154A9DD-2DE9-4ED4-821F-FFED4EC90D62";
    public static SendInvitationEmailToSigner: string = "D9C0BCDA-B359-4A67-97D7-128BAB99A523";
    public static SigningCertificatePaperSize: string = "025BBD2F-4048-4E45-ABE7-9E2069C471D1";
    public static SignatureRequestReplyAddress: string = "03301BE2-DAE7-49DE-851E-44E441BEAE38";
    public static SenderEmail: string = "B698C905-7766-4DCD-B2A2-C5C8F333AAA0";
    public static StampWatermarkSetting: string = "45AD3108-2229-4F74-BA93-077088F0D585";
    public static SelectDigitalCertificate: string = "E566D1E3-D9B3-4529-A739-41BB1C3346A7";
    public static Auto: string = "A79AAC55-CD5C-4B18-8CDD-5A86F5AA0409";
    public static Manual: string = "83A42615-9388-4849-A5C0-06474E5E5AC8";
    public static Weeks: string = "e3f79a7b-469d-456a-9a1e-76f2ea11af35";
    public static Days: string = "096DD340-6003-4770-90CB-DAC0A0001541";
    public static Page: string = "1F1728BC-8711-4ECD-B62E-D08C4D4B5A94";
    public static DraftIt: string = "3BCA6EA3-E26B-412D-A50A-52F3C14EBEE5";
    public static ViewSettings: string = "CC147CC8-ED00-47CE-939C-F120CC6894B8";
    public static ViewPDF: string = "5A1DE24B-ACD1-41BD-A300-4E646D5DECE8";
    public static Back: string = "958EE23F-6E23-4AEB-8B8D-091934AEE2D0";
    public static Send: string = "CBD33E2D-8F50-4A92-B934-183D03D0EE34";
    public static AcceptandContinue: string = "CBD33E2D-8F50-4A92-B934-183D03D0EE34";
    public static ResendEmail: string = "181670B1-BA07-48E9-A5B7-1BBC585C96CA";
    public static DistributeVertically: string = "FCB0EF2D-BE1C-4108-9178-D26B6CABE435";
    public static DistributeHorizontally: string = "4ACBB5B0-B418-43DC-A2E8-E13E6421454B";
    public static title_Alignselectedcontrolstoleft: string = "BABF8DC9-A66B-45DA-85E7-48C2BCE32175";
    public static title_Alignselectedcontrolstocenter: string = "7DE4AB94-982C-4AFF-8A56-7649333D80C4";
    public static title_Alignselectedcontrolstoright: string = "45359ABC-23FC-4B40-8883-34DBA307C58B";
    public static AlignTop: string = "AA70A8D5-BCA6-4914-8224-04232A689CC9";
    public static AlignMiddle: string = "82669E74-787A-48C2-806D-AEB4F1C93C85";
    public static AlignBottom: string = "06C0667C-2026-41AC-9656-E0FA601BCC9A";
    public static Planname: string = "D04991CF-D54B-404C-8878-AD2768F180DE";
    public static UnitsRemaining: string = "786FA05C-0018-4F4C-B6B4-0F0DFAF25B2E";
    public static SignatureControl: string = "E02FF794-35AC-4BE8-9B4E-3EA19CD329FC";
    public static Initials: string = "0f001fa1-c71f-490b-93cf-6e5a55abaad5";
    public static lang_dateTimeStamp: string = "6BB0ADA2-E324-4A22-87C3-587004F0C470";
    public static Name: string = "391FF1F4-6A14-4782-A7C9-0FA49F36A104";
    public static Title: string = "A17FC9D6-CA82-4E80-9F6B-9974AA1709F7";
    public static Company: string = "78348D80-2C99-4C2A-9A19-3483966A5AD6";
    public static lang_email: string = "35A18579-F298-4131-9DDF-FE6AD600D26F";
    public static lang_dropdown: string = "92AC70A4-9F89-4BBE-A819-3709353363D9";
    public static lang_checkbox: string = "A207142E-D7AB-4A43-9DD6-CC6164B93BF3";
    public static lang_radio: string = "3AD53391-9106-4CEF-BBC5-8E6A95565DB3";
    public static Date: string = "0EAB0BE2-0420-4D2D-AC8E-34FF54A73A3A";
    public static lang_Text: string = "5A870858-6BB7-4EF9-8889-1F51A637D04E";
    public static Label: string = "735BF0E9-FC01-40A6-82A3-5A037A3FF9B5";
    public static Hyperlink: string = "93D87429-C34A-4810-B3F1-570F6511BB26";
    public static FilesReview: string = "AE2454EB-95A0-4AA1-B6C9-644E4402B973";
    public static Description: string = "8E9D6844-5904-4C08-83CE-ECC621B36DAF";
    public static FirstLineBlank: string = "BFEBE496-868F-4823-B03C-92727C1007D0";
    public static Required: string = "93F9CE7C-3295-4B61-AA5B-6E116BC5F078";
    public static preSelected: string = "5AD8935E-32C4-4C0A-AE16-738053932F3E";
    public static checkboxGroupsOptional: string = "52258AAE-CC2F-4ED1-B8FC-1D8DCDF177F2";
    public static lang_defaultDateSigned: string = "97C864A2-653C-4830-8187-F42B6376B194";
    public static Signer: string = "C20C350E-1C6D-4C03-9154-2CC688C099CB";
    public static TextModalHeaderP: string = "E626AEFB-4D77-4D23-96FF-04BF184C6979";
    public static URL: string = "627A03AA-9CA4-48FA-BADB-6947E415D53C";
    public static TextColor: string = "1BBBAB56-D8D5-4F84-9396-F6DD50ABE600";
    public static GroupName: string = "2C91AA05-3BDB-4F22-9698-F26263CE318B";
    public static Size: string = "DAB925F0-3FB9-4F57-8B82-EE9BB2A68910";
    public static Preview: string = "258DBE19-9F81-4D8D-913F-0469AE78B324";
    public static FixedWidth: string = "0F2F1B37-CB4B-4D46-9448-0965ADE8A944";
    public static EntityFields: string = "954D609B-E686-4168-AD10-7AE8DA06C110";
    public static OnlycharactersAndnumbers: string = "CAFD9E78-1CA7-4A80-9AF1-B11C35E77DC6";
    public static Delete: string = "F42DF6C3-B200-4264-A9EF-E8F343161071";
    public static lang_confirmation: string = "A6EBEF12-7AEF-4082-BC87-FE29042430D7";
    public static lang_savedefault: string = "8DA068CF-9E61-4C8F-9CA8-357A32032822";
    public static Copy: string = "380EF85A-E330-4516-8675-DC7DFE285257";
    public static lang_addControllingField: string = "22AE37D8-F388-4101-ADB0-7948FE8015E8";
    public static lang_BackConfirmationP1: string = "44B55505-EA2C-4D63-BA92-B44D73621AE5";
    public static lang_BackConfirmationP2: string = "81870E93-D039-4B10-984F-9CCBD0EFFBC5";
    public static lang_BackConfirmationP3: string = "521D25B0-083A-4C3D-BF3B-6A56552FC478";
    public static No: string = "0E9CDDC4-25BF-4BF0-AD53-80D8C89E185D";
    public static Yes: string = "F0E0417A-2482-45FD-89B0-54269409999E";
    public static title_redoselectedControl: string = "274FDD6E-8CAF-43AC-8E4E-399E5A17851C";
    public static title_undoselectedControl: string = "083BE54A-C836-4794-965D-AD6853DA5DBD";
    public static lang_stillProceed: string = "48EA56F3-8705-4B74-92EE-8F1C8F9F26BD";
    public static Cancel: string = "66B1DC00-482B-4980-8710-AAFC3DADA3C3";
    public static OKText: string = "9D30B519-47FF-463A-82A5-6FBB42A57E5A";
    public static DiscardEnvelope: string = "731108F6-0E31-413B-B708-D0855F962F6F";
    public static Keyboardshortcuts: string = "15651090-39CB-4B68-A739-E3C70070A6D9";
    public static UseKeyboardshortcuts: string = "996BFC5B-B51E-4B8E-A122-7167CEEEF69E";
    public static Duplicate: string = "81FE0E72-95EF-450F-947B-D7BC8F73FB6C";
    public static Paste: string = "13864A68-DA9F-4282-A2D5-8B735536EE3A";
    public static Undo: string = "565D2E4B-CC1D-4250-A8BB-FEF920A29D39";
    public static Cut: string = "2FEADB24-4972-415F-8446-B557DF54DD90";
    public static Redo: string = "505A7B89-A01C-485E-A342-077D8798F6E8";
    public static SearchFields: string = "4BB2AD02-19B9-44FB-B710-E208DFAEE597";
    public static Fields: string = "1A625344-D5AE-41AD-98A4-D17F56F4DEA2";
    public static DocumentPreview: string = "FFECCD49-FD8E-4B87-BF9F-3D7495B3110D";
    public static Clearallcontrols: string = "A4497D9C-81FD-4DD1-B154-D9053D4234059";
    public static permanentlydeletesenvelope: string = "86E66EAD-12F0-4FDB-8B95-0B5D114F95D6";
    public static PlanDetails: string = "37F09EB9-BAD9-4C42-96EB-89314D297677";

    public static lang_BackConfirmation: string = "A1740045-2FB2-4C8E-BE8C-29436FBC4962";
    public static lang_BackConfirmationNo: string = "DB428375-4AAB-4B9E-AA2D-85F919305008";
    public static lang_BackConfirmationYes: string = "2EFEB645-2CD2-4D8F-A948-543A064848CA";

    public static ModifySelectedGroupSettingsTitle: string = "A12096AE-2F2F-4243-9BA8-541E0CCC7425";
    public static Update: string = "57A8EA2B-246A-4491-944B-ACC90CE67CE6";
    public static LearnMoreTextOnly: any = "30DAEF26-A701-4CB4-8C9D-9A63E5D760DB";
    public static freeTrial: any = "A6FD5C43-BF20-49C5-A321-616DD7ADC006";
    public static RSignMakesESignatureText: any = "EF529659-7036-4772-982C-0D7EF54A51F2";
    public static EnableMultipleBranding: string = "894BB07D-E934-431B-B055-41A4BC0B74E6";
    public static SignatureCertificateType: string = "917A063D-10B0-4C66-B191-F3439688B8FC";
    public static Old: string = "5AECF4BA-FACB-4BBF-A836-6C027DB8BFD5";
    public static NewBasic: string = "7725111D-3A1A-4765-A5C6-359813CA0231";

    public static lang_Session_Timeout_Redirecting: string = "D2709B8D-B480-40A8-99BD-F8BDB80D2C97";
    public static lang_Session_TimeOut: string = "E396654A-501E-4562-BA0B-8FE221823841";

    public static Home: string = "B77C9677-7217-4E21-9422-330C0CA983A1";
    public static SendTab: string = "1CE1860B-143A-4958-99A4-EC0967B21FFE";
    public static Envelopes: string = "66822ECC-1DF6-49AB-B1F3-88538CF1440F";
    public static Templates: string = "4A8C6D07-F4CA-41B5-BCD1-102CAE6615AE";
    public static Stats: string = "D86AD7F7-68D1-46E4-9BCD-FCB1157C0D45";
    public static Settings: string = "9A76FB60-3DE2-4C4E-9326-E81EB4CE9292";
    public static Translations: string = "98D8401A-98D6-4231-8CF9-843870FEDFAE";
    public static Hello: string = "A2B9797E-4120-4EBB-B602-BBA2D1F25F00";
    public static HideResipientDetailsInEmail: string = "56804266-61F4-4C07-8D85-A5AF88DFB4D2";

}