import { Component, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CommonService } from '../../services/common.service';
import { ResourceKeys } from 'src/app/constants/resourcekeys';

@Component({
    selector: 'app-keyboard-shortcuts',
    templateUrl: './keyboard-shortcuts.component.html',
    styleUrls: ['./keyboard-shortcuts.component.scss']
})
export class KeyboardShortcutsComponent {
    resourceKeys = ResourceKeys;
    @Output() keyboardShortcutsChangesMade: EventEmitter<any> = new EventEmitter();

    constructor(
        public modalRef: BsModalRef,
        private commonService: CommonService,
    ) { }

    ngOnInit(): void {
    }

    onDone(){
        this.modalRef.hide();
    }

    getLanguageTranslationValue(resourcekeyId: any) {
		return this.commonService.getLabelTranslationVal(resourcekeyId);
	}
}
