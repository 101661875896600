import { Injectable } from '@angular/core';
import { CommonConstants } from '../constants/common-constants';

// Define an interface for the control text object
interface ControlTextObject {
	saveAsDefault: any,
	required: any,
	fontFamily: string;
	fontWeight: any;
	textDecoration: string;
	fontStyle: string;
	fontSize: string;
	color: string;
	textAlign: string;
	lineHeight: string;
	validationType: string
	adlnName: string,
	adlnOption: string,
	preSelected: any,
	checkboxGroup: any,
	checkboxGroupName: any,
	checkboxName: any,
	dateFormat: string,
	size: string,
	fontTypeMeasurement: string,
	firstLineBlank: any,
	labelControlValue: string
}

// Define an interface for the dictionary containing control text objects
interface Dictionary {
	[key: string]: ControlTextObject;
}

@Injectable({
	providedIn: 'root'
})
export class DefaultControlStyleService {

	commonConstants = CommonConstants;
	latestSaveAsDefaultData: any[] = [];
	controlSaveAsObj: any = {};

	// Initialize dictionaries with default text properties for each control
	dict: Dictionary = {
		'sign': {
			saveAsDefault: undefined,
			required: true,
			fontFamily: 'Courier',
			fontWeight: 'normal',
			textDecoration: 'none',
			fontStyle: 'normal',
			fontSize: '12px',
			color: '#000',
			textAlign: 'left',
			lineHeight: '12px',
			validationType: '',
			adlnName: '',
			adlnOption: '',
			preSelected: false,
			checkboxGroup: false,
			checkboxGroupName: '',
			checkboxName: '',
			dateFormat: '',
			size: '',
			fontTypeMeasurement: 'px',
			firstLineBlank: false,
			labelControlValue: ''
		},
		'initials': {
			saveAsDefault: undefined,
			required: true,
			fontFamily: 'Courier',
			fontWeight: 'normal',
			textDecoration: 'none',
			fontStyle: 'normal',
			fontSize: '12px',
			color: '#000',
			textAlign: 'left',
			lineHeight: '12px',
			validationType: '',
			adlnName: '',
			adlnOption: '',
			preSelected: false,
			checkboxGroup: false,
			checkboxGroupName: '',
			checkboxName: '',
			dateFormat: '',
			size: '',
			fontTypeMeasurement: 'px',
			firstLineBlank: false,
			labelControlValue: ''
		},
		'datetimestamp': {
			saveAsDefault: undefined,
			required: false,
			fontFamily: 'Courier',
			fontWeight: 'normal',
			textDecoration: 'none',
			fontStyle: 'normal',
			fontSize: '12px',
			color: '#000',
			textAlign: 'left',
			lineHeight: '12px',
			validationType: '',
			adlnName: '',
			adlnOption: '',
			preSelected: false,
			checkboxGroup: false,
			checkboxGroupName: '',
			checkboxName: '',
			dateFormat: '',
			size: '',
			fontTypeMeasurement: 'px',
			firstLineBlank: false,
			labelControlValue: ''
		},
		'name': {
			saveAsDefault: undefined,
			required: true,
			fontFamily: 'Courier',
			fontWeight: 'normal',
			textDecoration: 'none',
			fontStyle: 'normal',
			fontSize: '12px',
			color: '#000',
			textAlign: 'left',
			lineHeight: '12px',
			validationType: '',
			adlnName: '',
			adlnOption: '',
			preSelected: false,
			checkboxGroup: false,
			checkboxGroupName: '',
			checkboxName: '',
			dateFormat: '',
			size: '',
			fontTypeMeasurement: 'px',
			firstLineBlank: false,
			labelControlValue: ''
		},
		'title': {
			saveAsDefault: undefined,
			required: true,
			fontFamily: 'Courier',
			fontWeight: 'normal',
			textDecoration: 'none',
			fontStyle: 'normal',
			fontSize: '12px',
			color: '#000',
			textAlign: 'left',
			lineHeight: '12px',
			validationType: '',
			adlnName: '',
			adlnOption: '',
			preSelected: false,
			checkboxGroup: false,
			checkboxGroupName: '',
			checkboxName: '',
			dateFormat: '',
			size: '',
			fontTypeMeasurement: 'px',
			firstLineBlank: false,
			labelControlValue: ''
		},
		'company': {
			saveAsDefault: undefined,
			required: true,
			fontFamily: 'Courier',
			fontWeight: 'normal',
			textDecoration: 'none',
			fontStyle: 'normal',
			fontSize: '12px',
			color: '#000',
			textAlign: 'left',
			lineHeight: '12px',
			validationType: '',
			adlnName: '',
			adlnOption: '',
			preSelected: false,
			checkboxGroup: false,
			checkboxGroupName: '',
			checkboxName: '',
			dateFormat: '',
			size: '',
			fontTypeMeasurement: 'px',
			firstLineBlank: false,
			labelControlValue: ''
		},
		'email': {
			saveAsDefault: undefined,
			required: true,
			fontFamily: 'Courier',
			fontWeight: 'normal',
			textDecoration: 'none',
			fontStyle: 'normal',
			fontSize: '12px',
			color: '#000',
			textAlign: 'left',
			lineHeight: '12px',
			validationType: '',
			adlnName: '',
			adlnOption: '',
			preSelected: false,
			checkboxGroup: false,
			checkboxGroupName: '',
			checkboxName: '',
			dateFormat: '',
			size: '',
			fontTypeMeasurement: 'px',
			firstLineBlank: false,
			labelControlValue: ''
		},
		'date': {
			saveAsDefault: undefined,
			required: true,
			fontFamily: 'Courier',
			fontWeight: 'normal',
			textDecoration: 'none',
			fontStyle: 'normal',
			fontSize: '12px',
			color: '#000',
			textAlign: 'left',
			lineHeight: '',
			validationType: '',
			adlnName: '',
			adlnOption: '',
			preSelected: false,
			checkboxGroup: false,
			checkboxGroupName: '',
			checkboxName: '',
			dateFormat: '',
			size: '',
			fontTypeMeasurement: 'px',
			firstLineBlank: false,
			labelControlValue: ''
		},
		'label': {
			saveAsDefault: undefined,
			required: true,
			fontFamily: 'Courier',
			fontWeight: 'normal',
			textDecoration: 'none',
			fontStyle: 'normal',
			fontSize: '12px',
			color: '#000',
			textAlign: 'left',
			lineHeight: '12px',
			validationType: '',
			adlnName: '',
			adlnOption: '',
			preSelected: false,
			checkboxGroup: false,
			checkboxGroupName: '',
			checkboxName: '',
			dateFormat: '',
			size: '',
			fontTypeMeasurement: 'px',
			firstLineBlank: false,
			labelControlValue: ''
		},
		'hyperlink': {
			saveAsDefault: undefined,
			required: true,
			fontFamily: 'Courier',
			fontWeight: 'normal',
			textDecoration: 'none',
			fontStyle: 'normal',
			fontSize: '12px',
			color: '#000',
			textAlign: 'left',
			lineHeight: '12px',
			validationType: '',
			adlnName: '',
			adlnOption: '',
			preSelected: false,
			checkboxGroup: false,
			checkboxGroupName: '',
			checkboxName: '',
			dateFormat: '',
			size: '',
			fontTypeMeasurement: 'px',
			firstLineBlank: false,
			labelControlValue: ''
		},
		'text': {
			saveAsDefault: undefined,
			required: true,
			fontFamily: 'Courier',
			fontWeight: 'normal',
			textDecoration: 'none',
			fontStyle: 'normal',
			fontSize: '12px',
			color: '#000',
			textAlign: 'left',
			lineHeight: '12px',
			validationType: 'text',
			adlnName: '',
			adlnOption: '',
			preSelected: false,
			checkboxGroup: false,
			checkboxGroupName: '',
			checkboxName: '',
			dateFormat: '',
			size: '',
			fontTypeMeasurement: 'px',
			firstLineBlank: false,
			labelControlValue: ''
		},
		'dropdown': {
			saveAsDefault: true,
			required: false,
			fontFamily: 'Courier',
			fontWeight: 'normal',
			textDecoration: 'none',
			fontStyle: 'normal',
			fontSize: '12px',
			color: '#000',
			textAlign: 'left',
			lineHeight: '12px',
			validationType: '',
			adlnName: '',
			adlnOption: '',
			preSelected: false,
			checkboxGroup: false,
			checkboxGroupName: '',
			checkboxName: '',
			dateFormat: '',
			size: '',
			fontTypeMeasurement: 'px',
			firstLineBlank: false,
			labelControlValue: ''
		},
		'checkbox': {
			saveAsDefault: true,
			required: false,
			fontFamily: 'Courier',
			fontWeight: 'normal',
			textDecoration: 'none',
			fontStyle: 'normal',
			fontSize: '12px',
			color: '#000',
			textAlign: 'left',
			lineHeight: '12px',
			validationType: '',
			adlnName: '',
			adlnOption: '',
			preSelected: false,
			checkboxGroup: false,
			checkboxGroupName: '',
			checkboxName: '',
			dateFormat: '',
			size: 'standard',
			fontTypeMeasurement: 'px',
			firstLineBlank: false,
			labelControlValue: ''
		},
		'radio': {
			saveAsDefault: true,
			required: false,
			fontFamily: 'Courier',
			fontWeight: 'normal',
			textDecoration: 'none',
			fontStyle: 'normal',
			fontSize: '12px',
			color: '#000',
			textAlign: 'left',
			lineHeight: '12px',
			validationType: '',
			adlnName: '',
			adlnOption: '',
			preSelected: false,
			checkboxGroup: false,
			checkboxGroupName: '',
			checkboxName: '',
			dateFormat: '',
			size: 'standard',
			fontTypeMeasurement: 'px',
			firstLineBlank: false,
			labelControlValue: ''
		}
	};

	constructor() { }

	// Function to update a specific dictionary based on its key
	updateDictionary(key: string, data: ControlTextObject): void {
		if (key.toLowerCase() === 'signature') {
			key = 'sign';
		}
		if (key.toLowerCase() === 'datetime') {
			key = 'datetimestamp';
		}
		if (key.toLowerCase() === 'newinitials') {
			key = 'initials';
		}

		if (key in this.dict) {
			this.dict[key] = data;
			// Here you can add code to save the updated data
		} else {
			console.log("Invalid key.");
		}
	}

	// Function to get the dictionary based on a key
	getDictionaryByKey(key: string): ControlTextObject {
		return this.dict[key]; //|| this.getDefaultControlTextObject();
	}

	getCtrlBasedSaveAsDefaultValue(key: string) {
		if (key.toLowerCase() === 'signature') {
			key = 'sign';
		}
		if (key.toLowerCase() === 'datetime') {
			key = 'datetimestamp';
		}
		if (key.toLowerCase() === 'newinitials') {
			key = 'initials';
		}
		return this.dict[key].saveAsDefault;
	}

	getDefaultControlTextObject() {
		return {
			saveAsDefault: true,
			required: true,
			fontFamily: 'Courier',
			fontWeight: 'normal',
			textDecoration: 'none',
			fontStyle: 'normal',
			fontSize: '12px',
			color: '#000',
			textAlign: 'left',
			lineHeight: '12px',
			validationType: '',
			adlnName: '',
			adlnOption: '',
			preSelected: false,
			checkboxGroup: false,
			dateFormat: '',
			size: 'standard',
			fontTypeMeasurement: 'px',
			firstLineBlank: false,
			labelControlValue: ''
		};
	}

	getRequiredFields(key: string) {
		if (key.toLowerCase() === 'signature') {
			key = 'sign';
		}
		if (key.toLowerCase() === 'datetime') {
			key = 'datetimestamp';
		}
		if (key.toLowerCase() === 'newinitials') {
			key = 'initials';
		}
		return this.dict[key].required;
	}

	updateSaveAsDefaultPayload(currentSelectedControl: any, ctrlName: any, spnctrlEle: any, fontFamilyWithoutQuotes: any, isRequiredChecked: any, typography: any) {
		let resizableCtrlEle: any = document.getElementById('resizable_' + currentSelectedControl);
		//let spnControlEle:any =
		if (ctrlName.toLowerCase() === 'signature') {
			ctrlName = 'sign'
		}
		if (ctrlName.toLowerCase() === 'datetime') {
			ctrlName = 'datetimestamp'
		}
		if (ctrlName.toLowerCase() === 'newinitials') {
			ctrlName = 'initials'
		}

		let hexColor: any = this.rgbToHex(spnctrlEle.style.color);
		if (ctrlName.toLowerCase() === 'sign' || ctrlName.toLowerCase() === 'initials') {
			this.controlSaveAsObj = {
				'Control': ctrlName.toLowerCase(),
				'Values': {
					'Color': hexColor,
					'Required': isRequiredChecked
				}
			}
		}
		if (ctrlName.toLowerCase() === 'datetimestamp' || ctrlName.toLowerCase() === 'hyperlink') {
			this.controlSaveAsObj = {
				'Control': ctrlName.toLowerCase(),
				'Values': {
					'Color': hexColor,
					'Bold': spnctrlEle.style.fontWeight ? (spnctrlEle.style.fontWeight === 'bold' || spnctrlEle.style.fontWeight === '600') ? true : false : false,
					'Underline': spnctrlEle.style.textDecoration ? (spnctrlEle.style.textDecoration === 'underline') ? true : false : false,
					'Italics': spnctrlEle.style.fontStyle ? (spnctrlEle.style.fontStyle === 'italic') ? true : false : false,
					'FontType': fontFamilyWithoutQuotes != null && fontFamilyWithoutQuotes != 'null' && fontFamilyWithoutQuotes != undefined ? fontFamilyWithoutQuotes : '',
					'FontSize': spnctrlEle.style.fontSize ? spnctrlEle.style.fontSize.split('px')[0] : 12,
					'FontTypeMeasurement': typography && typography != '' && typography != null ? typography : 'px',
					'TextAlign': spnctrlEle.style.textAlign ? spnctrlEle.style.textAlign : 'left'

				}
			}
		}
		if (ctrlName.toLowerCase() === 'name' || ctrlName.toLowerCase() === 'title' || ctrlName.toLowerCase() === 'company' ||
			ctrlName.toLowerCase() === 'email') {
			this.controlSaveAsObj = {
				'Control': ctrlName.toLowerCase(),
				'Values': {
					'Color': hexColor,
					'Required': isRequiredChecked,
					'Bold': spnctrlEle.style.fontWeight ? (spnctrlEle.style.fontWeight === 'bold' || spnctrlEle.style.fontWeight === '600') ? true : false : false,
					'Underline': spnctrlEle.style.textDecoration ? (spnctrlEle.style.textDecoration === 'underline') ? true : false : false,
					'Italics': spnctrlEle.style.fontStyle ? (spnctrlEle.style.fontStyle === 'italic') ? true : false : false,
					'FontType': fontFamilyWithoutQuotes != null && fontFamilyWithoutQuotes != 'null' && fontFamilyWithoutQuotes != undefined ? fontFamilyWithoutQuotes : '',
					'FontSize': spnctrlEle.style.fontSize ? spnctrlEle.style.fontSize.split('px')[0] : 12,
					'FontTypeMeasurement': typography && typography != '' && typography != null ? typography : 'px',
					'TextAlign': spnctrlEle.style.textAlign ? spnctrlEle.style.textAlign : 'left'

				}
			}
		}

		if (ctrlName.toLowerCase() === 'label') {
			this.controlSaveAsObj = {
				'Control': ctrlName.toLowerCase(),
				'Values': {
					'Color': hexColor,
					'Bold': spnctrlEle.style.fontWeight ? (spnctrlEle.style.fontWeight === 'bold' || spnctrlEle.style.fontWeight === '600') ? true : false : false,
					'Underline': spnctrlEle.style.textDecoration ? (spnctrlEle.style.textDecoration === 'underline') ? true : false : false,
					'Italics': spnctrlEle.style.fontStyle ? (spnctrlEle.style.fontStyle === 'italic') ? true : false : false,
					'FontType': fontFamilyWithoutQuotes != null && fontFamilyWithoutQuotes != 'null' && fontFamilyWithoutQuotes != undefined ? fontFamilyWithoutQuotes : '',
					'FontSize': spnctrlEle.style.fontSize ? spnctrlEle.style.fontSize.split('px')[0] : 12,
					'FontTypeMeasurement': typography && typography != '' && typography != null ? typography : 'px',
					'TextAlign': spnctrlEle.style.textAlign ? spnctrlEle.style.textAlign : 'left',
					'LabelControlValue': spnctrlEle.innerText ? spnctrlEle.innerText : ''
				}
			}
		}

		if (ctrlName.toLowerCase() === 'date') {
			let prefillDateCheckbox: any = document.getElementById('prefillDateCheckbox');
			if (resizableCtrlEle && prefillDateCheckbox) {
				if (resizableCtrlEle.getAttribute('data-defaultdate') == 'true') {
					prefillDateCheckbox.checked = true;
				} else {
					prefillDateCheckbox.checked = false;
				}
			}
			this.controlSaveAsObj = {
				'Control': ctrlName.toLowerCase(),
				'Values': {
					'Color': hexColor,
					'Required': isRequiredChecked,
					'Bold': spnctrlEle.style.fontWeight ? (spnctrlEle.style.fontWeight === 'bold' || spnctrlEle.style.fontWeight === '600') ? true : false : false,
					'Underline': spnctrlEle.style.textDecoration ? (spnctrlEle.style.textDecoration === 'underline') ? true : false : false,
					'Italics': spnctrlEle.style.fontStyle ? (spnctrlEle.style.fontStyle === 'italic') ? true : false : false,
					'FontType': fontFamilyWithoutQuotes != null && fontFamilyWithoutQuotes != 'null' && fontFamilyWithoutQuotes != undefined ? fontFamilyWithoutQuotes : '',
					'FontSize': spnctrlEle.style.fontSize ? spnctrlEle.style.fontSize.split('px')[0] : 12,
					'FontTypeMeasurement': typography && typography != '' && typography != null ? typography : 'px',
					'TextAlign': spnctrlEle.style.textAlign ? spnctrlEle.style.textAlign : 'left',
					'IsDefaultRequired': prefillDateCheckbox ? prefillDateCheckbox.checked : false,
					'DateFormat': spnctrlEle.innerText ? spnctrlEle.innerText : ''
				}
			}
		}

		if (ctrlName.toLowerCase() === 'dropdown') {
			let drpFirstLineBlank: any = document.getElementById('drpFirstLineBlank');
			if (resizableCtrlEle && drpFirstLineBlank) {
				if (resizableCtrlEle.getAttribute('data-firstlineblank') == 'true') {
					drpFirstLineBlank.checked = true;
				} else {
					drpFirstLineBlank.checked = false;
				}
			}

			this.controlSaveAsObj = {
				'Control': ctrlName.toLowerCase(),
				'Values': {
					'Color': hexColor,
					'Required': isRequiredChecked,
					'PreSelected': drpFirstLineBlank ? drpFirstLineBlank.checked : false
				}
			}
		}

		if (ctrlName.toLowerCase() === 'checkbox') {
			let chkPreSelected: any = document.getElementById('chkPreSelected');
			if (resizableCtrlEle && chkPreSelected) {
				if (resizableCtrlEle.getAttribute('data-selected1') == 'true') {
					chkPreSelected.checked = true;
				} else {
					chkPreSelected.checked = false;
				}
			}

			let checkboxgroup: any = document.getElementById('checkboxgroup');
			if (resizableCtrlEle && checkboxgroup) {
				if (resizableCtrlEle.getAttribute('data-isgroupchecked') == 'true') {
					checkboxgroup.checked = true;
				} else {
					checkboxgroup.checked = false;
				}
			}

			let dataSize: any = resizableCtrlEle.getAttribute('data-size');

			this.controlSaveAsObj = {
				'Control': ctrlName.toLowerCase(),
				'Values': {
					'Required': isRequiredChecked,
					'PreSelected': chkPreSelected ? chkPreSelected.checked : false,
					'CheckboxGroup': checkboxgroup ? checkboxgroup.checked : false,
					'Size': dataSize ? dataSize : 'standard'
				}
			}
		}

		if (ctrlName.toLowerCase() === 'radio') {
			let chkRadioRequiredSelected: any = document.getElementById('chkRadioRequiredSelected');
			if (resizableCtrlEle && chkRadioRequiredSelected) {
				if (resizableCtrlEle.getAttribute('data-selected') == 'true') {
					chkRadioRequiredSelected.checked = true;
				} else {
					chkRadioRequiredSelected.checked = false;
				}
			}

			let chkPreSelected: any = document.getElementById('chkPreSelected');
			if (resizableCtrlEle && chkPreSelected) {
				if (resizableCtrlEle.getAttribute('data-selected1') == 'true') {
					chkPreSelected.checked = true;
				} else {
					chkPreSelected.checked = false;
				}
			}

			let dataSize: any = resizableCtrlEle.getAttribute('data-size');

			this.controlSaveAsObj = {
				'Control': ctrlName.toLowerCase(),
				'Values': {
					'Required': chkRadioRequiredSelected ? chkRadioRequiredSelected.checked : false,
					'PreSelected': chkPreSelected ? chkPreSelected.checked : false,
					'Size': dataSize ? dataSize : 'standard'
				}
			}
		}

		if (ctrlName.toLowerCase() === 'text') {
			let dataValidationType: any;
			let dataAdditionalValidationName: string = '';
			let dataAdditionalValidationOption: string = '';
			let validationOption: any = '';

			dataValidationType = resizableCtrlEle.getAttribute('data-validation-type');
			dataAdditionalValidationName = resizableCtrlEle.getAttribute('data-additional-validation-name');
			dataAdditionalValidationOption = resizableCtrlEle.getAttribute('data-additional-validation-option');

			if (dataValidationType && dataValidationType.toLowerCase() === 'zip') {
				validationOption = this.commonConstants.ValidationsZipOptions[parseInt(dataAdditionalValidationOption) - 1].viewname;
			}
			if (dataValidationType && dataValidationType.toLowerCase() === 'numeric' && dataAdditionalValidationOption != null) {
				validationOption = this.commonConstants.AdditionalValidationType[parseInt(dataAdditionalValidationOption) - 1].viewValue;
			}
			if (dataValidationType && dataValidationType.toLowerCase() === 'date' && dataAdditionalValidationOption != null) {
				validationOption = this.commonConstants.ValidationsDateOptions[parseInt(dataAdditionalValidationOption) - 1].label;
			}

			let dataValidationTypeValue: any = this.commonConstants.ValidationsText[0].value;
			if (this.commonConstants.ValidationsText) {
				this.commonConstants.ValidationsText.forEach((ele: any) => {
					if (ele.label.toLowerCase() === dataValidationType.toLowerCase()) {
						dataValidationTypeValue = ele.value;
					}
				})
			}


			this.controlSaveAsObj = {
				'Control': ctrlName.toLowerCase(),
				'Values': {
					'Color': hexColor,
					'Required': isRequiredChecked,
					'Bold': spnctrlEle.style.fontWeight ? (spnctrlEle.style.fontWeight === 'bold' || spnctrlEle.style.fontWeight === '600') ? true : false : false,
					'Underline': spnctrlEle.style.textDecoration ? (spnctrlEle.style.textDecoration === 'underline') ? true : false : false,
					'Italics': spnctrlEle.style.fontStyle ? (spnctrlEle.style.fontStyle === 'italic') ? true : false : false,
					'FontType': fontFamilyWithoutQuotes != null && fontFamilyWithoutQuotes != 'null' && fontFamilyWithoutQuotes != undefined ? fontFamilyWithoutQuotes : '',
					'FontSize': spnctrlEle.style.fontSize ? spnctrlEle.style.fontSize.split('px')[0] : 12,
					'FontTypeMeasurement': typography && typography != '' && typography != null ? typography : 'px',
					'TextAlign': spnctrlEle.style.textAlign ? spnctrlEle.style.textAlign : 'left',
					'ValidationType': dataValidationTypeValue ? dataValidationTypeValue : '',
					'AdlnName': dataAdditionalValidationName ? dataAdditionalValidationName : '',
					'AdlnOption': validationOption ? validationOption : ''
				}
			}
		}

		this.latestSaveAsDefaultData.push(this.controlSaveAsObj);

		let newData: any = [];

		this.latestSaveAsDefaultData.forEach((item: { Control: any; Values: any; }) => {
			const existingItemIndex = newData.findIndex((existingItem: { Control: any; }) => existingItem.Control === item.Control);
			if (existingItemIndex !== -1) {
				// If item already exists, update its values
				newData[existingItemIndex].Values = item.Values;
			} else {
				// If item doesn't exist, push it
				newData.push(item);
			}
		});
		this.latestSaveAsDefaultData = newData;
		return newData;
	}

	getSaveAsDefaultData() {
		return this.latestSaveAsDefaultData;
	}

	updateSaveAsDefaultData(data: any) {
		this.latestSaveAsDefaultData = data;
	}

	rgbToHex(rgb: any) {
		// Extracting the RGB components
		let hexColor: any = rgb;
		let rgbArray: any = rgb.match(/\d+/g);
		if (rgbArray) {
			let r: any = parseInt(rgbArray[0]);
			let g: any = parseInt(rgbArray[1]);
			let b: any = parseInt(rgbArray[2]);

			// Converting each component to hexadecimal
			let rHex: any = r.toString(16).padStart(2, '0');
			let gHex: any = g.toString(16).padStart(2, '0');
			let bHex: any = b.toString(16).padStart(2, '0');

			// Constructing the hexadecimal color code
			hexColor = '#' + rHex + gHex + bHex;

		}
		return hexColor;
	}

	getControlSaveAsObj() {
		return this.controlSaveAsObj;
	}

}
