import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
@Component({
    selector: 'app-multi-branding',
    templateUrl: './multi-branding.component.html',
    providers: [
        { provide: CarouselConfig, useValue: { interval: false, noPause: false, showIndicators: false } }
    ],
    styleUrls: ['./multi-branding.component.scss']
})
export class MultiBrandingComponent {
    noWrapSlides:boolean = false;
    showIndicator:boolean = true;
    shouldBeChecked:boolean = false;
    slides: any[] = [];
    @Output() multiBrandingChangesMade: EventEmitter<any> = new EventEmitter();

    // slides = [
    //     { image: '../../../assets/images/slide1.png', text: 'FedEx', id: 1 },
    //     { image: '../../../assets/images/slide2.png', text: 'Nike Red', id: 2 },
    //     { image: '../../../assets/images/slide3.png', text: 'Jungheinrich', id: 3 },
    //     { image: '../../../assets/images/slide4.png', text: 'Nike Black', id: 4 },
    //     { image: 'https://valor-software.com/ngx-bootstrap/assets/images/nature/1.jpg', text: 'First', id: 5 },
    //     { image: 'https://valor-software.com/ngx-bootstrap/assets/images/nature/2.jpg', text: 'Second', id: 6 },
    //     { image: 'https://valor-software.com/ngx-bootstrap/assets/images/nature/3.jpg', text: 'Third', id: 7 },
    //     { image: 'https://valor-software.com/ngx-bootstrap/assets/images/nature/4.jpg', text: 'Fourth', id: 8 }
    // ];
    itemsPerSlide: any;
    isEnvlpEdited: any;

    constructor(
        public modalRef: BsModalRef
    ) { }

    ngOnInit(): void {
        this.itemsPerSlide = this.mobileDevice() ? 2 : 3;
    }

    onOk() {
        this.modalRef.hide();
        this.slides.forEach((ele: any, index: any) => {
            let sliderChecked: any = document.getElementById('slider' + (index + 1));
            if (sliderChecked && sliderChecked.checked === true) {
                ele.isDefault = sliderChecked.checked;
            } else {
                ele.isDefault = false;
            }
        });
        this.multiBrandingChangesMade.emit(this);
    }

    private mobileDevice(): boolean {
        return navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i)
            ? true : false;
    }

}
