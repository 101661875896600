<header class="headerFixed">
	<div class="logo-wrapper">
		<a (click)="redirectTo('Home')" href="javascript: false;">
			<img class="rsign-logo liNav" src="../../../assets/images/RSign.svg" alt="image" />
		</a>
		<a class="rcircle-logo" (click)="redirectTo('Home')" href="javascript: false;">
			<img class="liNav" src="../../../assets/images/RPost-Button-White.svg" alt="image" />
		</a>
	</div>
	<nav class="navbar navbar-default py-0" role="navigation">
		<div class="collapse navbar-collapse d-flex align-items-center">
			<ul class="nav navbar-nav align-items-center" [ngClass]="disableNavigationBar === 'true' ? 'wrapper-cursor' : ''">
				<li class="text-center liNav" [ngClass]="disableNavigationBar === 'true' ? 'pointer-events-none' : ''">
					<a class="nav-link d-flex flex-column text-white" href="{{HomeUrl}}">
						<span class="home"></span>
						{{getLanguageTranslationValue(resourceKeys.Home)}}
					</a>
				</li>
				<li id="sendNav" class="text-center liNav" [ngClass]="disableNavigationBar === 'true' ? 'pointer-events-none' : ''">
					<a class="nav-link d-flex flex-column text-white" href="{{SendUrl}}">
						<span class="send"></span>
						{{getLanguageTranslationValue(resourceKeys.SendTab)}}
					</a>
				</li>
				<li class="text-center liNav" [ngClass]="disableNavigationBar === 'true' ? 'pointer-events-none' : ''">
					<a class="nav-link d-flex flex-column text-white" href="{{EnvelopeUrl}}">
						<span class="manage"></span>
						{{getLanguageTranslationValue(resourceKeys.Envelopes)}}
					</a>
				</li>
				<li id="templateNav" class="text-center liNav" [ngClass]="disableNavigationBar === 'true' ? 'pointer-events-none' : ''">
					<a class="nav-link d-flex flex-column text-white" href="{{TemplateUrl}}">
						<span class="managetemplate"></span>
						{{getLanguageTranslationValue(resourceKeys.Templates)}}
					</a>
				</li>
				<li *ngIf="displayStats == true" class="text-center liNav" [ngClass]="disableNavigationBar === 'true' ? 'pointer-events-none' : ''">
					<a class="nav-link d-flex flex-column text-white" href="{{StatsUrl}}">
						<span class="managestats"></span>
						{{getLanguageTranslationValue(resourceKeys.Stats)}}
					</a>
				</li>
				<li class="text-center liNav" [ngClass]="disableNavigationBar === 'true' ? 'pointer-events-none' : ''">
					<a class="nav-link d-flex flex-column text-white" href="{{SettingsUrl}}">
						<span class="settingsAdmin"></span>
						{{getLanguageTranslationValue(resourceKeys.Settings)}}
					</a>
				</li>
				<li *ngIf="isLanguageTranslator == true" class="text-center liNav" [ngClass]="disableNavigationBar === 'true' ? 'pointer-events-none' : ''">
					<a class="nav-link d-flex flex-column text-white" href="{{TranslationsUrl}}">
						<span class="translationsAdmin"></span>
						{{getLanguageTranslationValue(resourceKeys.Translations)}}
					</a>
				</li>
			</ul>
			<div class="navLogout">
				<div id="usernamelogout" class="text-right">
					<a *ngIf="displayOutOfOfficeLabel == true" title="" style="text-decoration: none;"
					    (click)="OutOfOfficeMode()" class="out-off-mode-txt"><span>Out of Office Mode</span>
					</a>

					<a target="_blank" title="Click on this link to upgrade the plan"
						href="https://www.rmail.com/upgrade" class="upgrade-link">
					</a>
					<div [ngClass]="disableNavigationBar === 'true' ? 'wrapper-cursor' : ''">
						<a [ngClass]="disableNavigationBar === 'true' ? 'pointer-events-none' : ''" title="Click here to edit profile" id="alink" class="profEdit" href="{{UserDetailsUrl}}">
							<span [ngClass]="disableNavigationBar === 'true' ? 'pointer-events-none' : ''" class="hello-text">{{getLanguageTranslationValue(resourceKeys.Hello)}}, <strong class="spnUsername"> {{userName}}</strong></span>
							<span [ngClass]="disableNavigationBar === 'true' ? 'pointer-events-none' : ''" class="langFlagIcon" id="divFlagIcon"></span>
						</a>
					</div>

					<a title="RPost Help Center" id="pageHelpInfo" style="color: #ffffff;"
						(click)="redirectCorporateURL('RSignSettings','new')" class="ques-icon">
						<i class="fa fa-question-circle fa-2x" aria-hidden="true"></i>
					</a>
					<span class="logoutbtn active" (click)="logout()">
						<a id="logouttap" class="logout rsignuserlogout" style="z-index: 500;">
							Sign out</a>
					</span>
				</div>
			</div>
		</div>
	</nav>
</header>
<div *ngIf="showLoader" class="loader"></div>