import { Component, HostListener, OnInit } from '@angular/core';
// import { Router } from '@angular/router';
// import { EnvelopeStatusText, MessageTypes, ResourceKeys } from 'src/app/constants/common-constants';
// import { SignerLandingService } from '../../services/signer-landing.service';
// import { CommonConstants } from '../../constants/common-constants';
import { DomSanitizer, Title } from '@angular/platform-browser';
// import { TranslationsService } from '../../services/translations.service';
// import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/services/common.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { SessionStorageService } from '../../services/session-storage.service';
@Component({
	selector: 'app-global-error',
	templateUrl: './global-error.component.html',
	styleUrls: ['./global-error.component.scss']
})
export class GlobalErrorComponent implements OnInit {
	title = 'RSign | Global Error';

	showLoader: boolean = false;
	isApplicationErrorFlag: boolean = true;
	htmlFooterString: any;
	DateTimeMessage1: any;
	DateTimeMessage2: any;

	constructor(private commonService: CommonService, private titleService: Title,
		private NavigationService: NavigationService,
		private sessionStorageService: SessionStorageService

	) { }

	@HostListener('window:popstate', ['$event'])
	onPopState() {
		//window.location.reload();
		this.NavigationService.reloadPage();
	}

	@HostListener('window:load')
	onLoad() {
		let element: any = (document.getElementById('body-view')) as HTMLSelectElement;
		element.classList.add("global-page");
	}

	ngOnInit(): void {
		this.titleService.setTitle(this.title);
		this.showLoader = true;
		window.scrollTo({ top: 0, behavior: 'smooth' });

		this.htmlFooterString = '<p> ' +
			'<a href="https://rpost.com/legal-notices/terms-and-conditions" target="_blank" class="footerLinks">General Terms & Legal Notices</a>: Click to view service level terms, conditions, privacy, ' +
			'<a class="footerLinks" href="https://rpost.com/legal-notices/legal-and-patent-notice" target="_blank"> patent, trademark</a>, and other service and legal notices. ' +
			'</p>';

		// let envelopeId:any = localStorage.getItem("EnvelopeID");
		// let emailAddr:any = localStorage.getItem("EmailId");
		//let pagename: any = localStorage.getItem("PageName");

		let intializePrepareDetails:any = this.sessionStorageService.getIntializePrepareDetails();
		let envelopeId:any = intializePrepareDetails.EnvelopeID;
		let emailAddr:any = intializePrepareDetails.EmailId;
		let pagename:any = intializePrepareDetails.PageName;

		let date = new Date();
		let currentMonth: string = (date.getUTCMonth() + 1).toString();
		let currentDate: string = date.getUTCDate().toString();
		if (currentMonth.length == 1) currentMonth = "0" + currentMonth;
		if (currentDate.length == 1) currentDate = "0" + currentDate;

		this.DateTimeMessage1 = currentMonth + currentDate + date.getUTCFullYear() + date.getUTCHours() + date.getUTCMinutes() + date.getUTCMilliseconds();

		if (envelopeId != null && envelopeId != "null" && envelopeId != "" && envelopeId != undefined) {
			this.DateTimeMessage1 = this.DateTimeMessage1 + "|" + envelopeId;
		}
		this.DateTimeMessage1 = this.DateTimeMessage1 + "|";
		if (emailAddr != null && emailAddr != "null" && emailAddr != "" && emailAddr != undefined
			&& pagename != null && pagename != "" && pagename != "null" && pagename != undefined) {

			let maskedEmailString = this.commonService.GenerateMaskedEmail(emailAddr);
			this.DateTimeMessage2 = maskedEmailString + "|" + pagename;
		}
		else if (emailAddr != null && emailAddr != "null" && emailAddr != "" && emailAddr != undefined) {
			let maskedEmailString = this.commonService.GenerateMaskedEmail(emailAddr);
			this.DateTimeMessage2 = maskedEmailString;
		}
		else if (pagename != null && pagename != "" && pagename != "null" && pagename != undefined) {
			this.DateTimeMessage2 = pagename;
		}

		this.showLoader = false;
		this.clearStorage();
	}

	redirectTo(type: any) {
		this.clearStorage();
		this.commonService.redirectTo(type);
	}

	clearStorage(){		
		let userInfo: any = this.sessionStorageService.getUserDetails();		
		sessionStorage.clear();		
		this.sessionStorageService.setUserDetails(userInfo);
	}
}
