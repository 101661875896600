import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ServicesError } from './services.error';
import { ApiUrlConfig } from '../constants/common-apiurls';
import { CommonConstants } from '../constants/common-constants';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})

export class APIService {
  baseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient, private error: ServicesError, private authService: AuthService) { }

  get requestOptions() {
    return {
      headers: new HttpHeaders({
        'Authorization': CommonConstants.TokenType + this.authService.getToken(), 
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json;'
      })
    };
  }

  get(url: any) {
    return this.http.get(this.baseUrl + url, { ...this.requestOptions }).pipe(catchError(this.error.handleError));
  }

  viewPdf(apiEnvelopeControls: any) {
    let viewPdfUrl = ApiUrlConfig.ViewPdf;
    return this.http.post(this.baseUrl + viewPdfUrl, apiEnvelopeControls, { ...this.requestOptions }).pipe(catchError(this.error.handleError));
  }

  SendEnvelope(apiEnvelopeControls: any) {
    let sendEnvelopeUrl = ApiUrlConfig.SendEnvelope;
    return this.http.post(this.baseUrl + sendEnvelopeUrl, apiEnvelopeControls, { ...this.requestOptions }).pipe(catchError(this.error.handleError));
  }

  delete(url: any) {
    return this.http.delete(this.baseUrl + url, { ...this.requestOptions }).pipe(catchError(this.error.handleError));
  }

  SaveAsDraftEnvelope(apiEnvelopeControls: any) {
    let saveAsDraftEnvelopeUrl = ApiUrlConfig.SaveAsDraftEnvelope;
    return this.http.post(this.baseUrl + saveAsDraftEnvelopeUrl, apiEnvelopeControls, { ...this.requestOptions }).pipe(catchError(this.error.handleError));
  }

  GetRefreshToken(tokenModel: any) {
    let getRefreshTokenUrl = ApiUrlConfig.RefreshToken;
    return this.http.post(this.baseUrl + getRefreshTokenUrl, tokenModel, { ...this.requestOptions }).pipe(catchError(this.error.handleError));
  }
  OpenEnvelope(apiEnvelopeControls: any) {
    let openEnvelopeUrl = ApiUrlConfig.OpenEnvelope;
    return this.http.post(this.baseUrl + openEnvelopeUrl, apiEnvelopeControls, { ...this.requestOptions }).pipe(catchError(this.error.handleError));
  }

  getRedirectURL(url: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    })
    return this.http.get(url, { headers }).pipe(
      catchError(this.error.handleError)
    );
  }

  saveSettings(SettingsPrepareModal: any) {
    let saveSettingsUrl = ApiUrlConfig.SaveDefaultSettings;
    return this.http.post(this.baseUrl + saveSettingsUrl, SettingsPrepareModal, { ...this.requestOptions }).pipe(catchError(this.error.handleError));
  }

  SaveTemplateRuleUrl(apiTemplateControls:any){
    let SaveTemplateRuleUrl=ApiUrlConfig.SaveAsTemplate;
    return this.http.post(this.baseUrl + SaveTemplateRuleUrl, apiTemplateControls, { ...this.requestOptions }).pipe(catchError(this.error.handleError));
  }

  viewTemplatePdf(apiEnvelopeControls: any) {
    let viewPdfUrl = ApiUrlConfig.ViewTemplatePdf;
    return this.http.post(this.baseUrl + viewPdfUrl, apiEnvelopeControls, { ...this.requestOptions }).pipe(catchError(this.error.handleError));
  }

  getBrandingDetails(companyId: any) {
    let openEnvelopeUrl = ApiUrlConfig.GetMultibrandingByCompanyId;
    const params = new HttpParams().set('companyId', companyId);
    return this.http.get(this.baseUrl + openEnvelopeUrl, { params })
      .pipe(catchError(this.error.handleError));
  }

  post(url: any, reqObj:any ) {
    return this.http.post(this.baseUrl + url, reqObj, { ...this.requestOptions }).pipe(catchError(this.error.handleError));
  }

}
