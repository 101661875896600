import { Component, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ResourceKeys } from 'src/app/constants/resourcekeys';
import { CommonService } from '../../services/common.service';
import { SessionStorageService} from '../../services/session-storage.service';

@Component({
	selector: 'app-goto-step-one',
	templateUrl: './goto-step-one.component.html',
	styleUrls: ['./goto-step-one.component.scss']
})
export class GotoStepOneComponent {
	resourceKeys = ResourceKeys;
	@Output() gotoStepOneChangesMade: EventEmitter<any> = new EventEmitter();
	yesMessage:string = '';

	constructor(
		public modalRef: BsModalRef,
		private commonService: CommonService,
		private sessionStorageService: SessionStorageService
	) { }

	ngOnInit(): void {
		let prepareType:any = this.sessionStorageService.intializePrepareDetails.PrepareType;
		this.yesMessage = '';
		if(prepareType.toLowerCase() === 'template'){
			this.yesMessage = 'Goes to the previous step after saving changes.';
		} else {
			this.yesMessage = 'Goes to the previous step after saving documents to the Drafts folder on the home page.';
		}
	}

	getLanguageTranslationValue(resourcekeyId: any) {
		return this.commonService.getLabelTranslationVal(resourcekeyId);
	}

	onGotoStepOne(type:any){
		let popUpStatus = {status: type};
        this.gotoStepOneChangesMade.next(popUpStatus);
		this.modalRef.hide();
	}
}
