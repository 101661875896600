import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-initials-creation',
    templateUrl: './initials-creation.component.html',
    styleUrls: ['./initials-creation.component.scss']
})
export class InitialsCreationComponent {

    @Input() initialStateObj: any = {};
    @Output() initialsChangesMade: EventEmitter<any> = new EventEmitter();
    selectedOption: any = 'Place On All Pages';
    firstInputval: any = 0;
    secondInputval: any = 0;
    showLoader:boolean = false;

    constructor(
        public modalRef: BsModalRef,
        private toastrService: ToastrService
    ) { }

    onSelectedOption(option: any) {
        this.selectedOption = option;
        if (this.selectedOption === 'Place On All Pages') {
            let fromPageNumber: any = document.getElementById('fromPageNumber');
            if(fromPageNumber){
                fromPageNumber.value = '';
            }
            let toPageNumber: any = document.getElementById('toPageNumber');
            if(toPageNumber){
                toPageNumber.value = '';
            }
        }
    }

    createTypeOfInitialsApply() {
        let fromPageNumber: any = document.getElementById('fromPageNumber');
        let toPageNumber: any = document.getElementById('toPageNumber');
        this.firstInputval = 0;
        this.secondInputval = 0;
        if (this.selectedOption !== 'Place On All Pages') {
            if (fromPageNumber && toPageNumber && fromPageNumber.value != '' && toPageNumber.value != '') {
                this.firstInputval = parseInt(fromPageNumber.value);
                this.secondInputval = parseInt(toPageNumber.value);
            }

            if (this.firstInputval === 0 || this.secondInputval === 0) {
                this.showToastrWarningMsg("The From and To page values shouldn't be blank.");
            }
            else if (this.firstInputval !== this.initialStateObj.totalImages && this.secondInputval !== this.initialStateObj.totalImages && this.firstInputval >= this.initialStateObj.totalImages && this.initialStateObj.totalImages <= this.secondInputval) {
                this.showToastrWarningMsg('The From and To page range should be 1 to '+ this.initialStateObj.totalImages +' only.');
            }
            else if (this.secondInputval < this.firstInputval) {
                this.showToastrWarningMsg('Start page must be less than end page.');
            }
            else if (this.secondInputval > this.initialStateObj.totalImages) {
                this.showToastrWarningMsg('The From and To page range should be 1 to '+ this.initialStateObj.totalImages +' only.');
            }
            else if (this.firstInputval > this.initialStateObj.totalImages) {
                this.showToastrWarningMsg('The From and To page range should be 1 to '+ this.initialStateObj.totalImages +' only.');
            }
            else {
                this.showLoader = true;
                this.initialsChangesMade.next(this);
                this.modalRef.hide();
            }
        } else {
            this.showLoader = true;
            this.secondInputval = this.initialStateObj.totalImages;
            this.initialsChangesMade.next(this);
            this.modalRef.hide();
        }
    }

    checkRange(event: Event) {
        const input = event.target as HTMLInputElement;
        let value = input.value;

        // Limit to 3 digits
        if (value.length) {
            value = value.replace(/\D/g, '');
            value = value.substring(0, 3); // Take only the first 3 characters
            input.value = value; // Update the input value
        }

        // Convert to number for range checking
        const numericValue = Number(value);

        // Enforce min and max range
        if (numericValue < 1) {
            input.value = '';
        } else if (numericValue > 999) {
            input.value = '999';
        }
    }

    showToastrWarningMsg(msg:string){
        this.toastrService.warning(msg, 'Warning');
    }

}
