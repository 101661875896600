export const environment = {
  production: false,
  uiAngularBaseUrl: 'https://send.use.rsign.com/',
  apiBaseUrl: 'https://sendapi.use.rsign.com/',
  brandUrl: 'https://webapi.s1.rpost.info/api/v1/brand',
  sessionTimeOut: 1200,
  sessionInActivity: 300,
  rsignUrl: 'https://app3.use.rsign.com/',
  rsignSFAppUrl: 'https://sfapp.use.rsign.com/',
  corporateURLApiBaseUrl: 'https://signapi.use.rsign.com'
};