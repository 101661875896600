import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    reloadPage() {
        window.location.reload();
    }

    redirect(url: string): void {
        window.location.href = url;
    }
}
