import { Injectable } from '@angular/core';
import { ControlPropertyService } from './control.properties.service';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CashedService {

    private getDisplayStyleCached: { [key: string]: any } = {};
    private controlTitleCache: { [key: string]: string } = {};

    constructor(
        private controlPropertyService: ControlPropertyService
    ) { }

    // cashe method
    getDisplayStyleWidthCached(item: any, getAllImagesProcessed:any) {
        const key = item.id; //JSON.stringify(item);
        if (!this.getDisplayStyleCached[key]) {
            this.getDisplayStyleCached[key] = this.getDisplayStyleCachedCompute(item);
        }

        return this.getDisplayStyleCached[key];
    }

    private getDisplayStyleCachedCompute(item: any) {
        let defaultWidth: any = '915px';
        if (item && item.width != null && item.width != '' && item.width != undefined) {
            defaultWidth = item.width;
        }

        return { 'width': defaultWidth };
    }

    getControlOriginalTitle(recipientId: string, currentSelectedControl: string): Observable<string> {
        const cacheKey = this.generateControlTitleCacheKey(recipientId, currentSelectedControl); // Generate a unique cache key
        // Check if the value is already cached
        if (this.controlTitleCache[cacheKey]) {
            return of(this.controlTitleCache[cacheKey]); // Return cached value as an observable
        }

        // If not cached, simulate any other fetching logic)
        let resizableCurrentControl: any = document.getElementById("resizable_" + currentSelectedControl);
        if (resizableCurrentControl) {
            recipientId = resizableCurrentControl.getAttribute('data-rcptid');
        }

        const result = this.controlPropertyService.getControlOriginalTitle(recipientId, currentSelectedControl);

        // Cache the result
        this.controlTitleCache[cacheKey] = result;

        // console.log('Returning fresh value');
        return of(result); // Return the fresh value as an observable
    }

    // Helper method to generate a unique cache key
    private generateControlTitleCacheKey(recipientId: string, currentSelectedControl: string): string {
        return `${recipientId}_${currentSelectedControl}`;
    }

}
