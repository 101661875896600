import { Component, OnInit, Input, HostListener, ViewChild, ElementRef } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ContextualMenu } from './contextual-menu';
import { SessionStorageService } from 'src/app/services/session-storage.service';
@Component({
	selector: 'app-contextual-menu',
	templateUrl: './contextual-menu.component.html',
	styleUrls: ['./contextual-menu.component.scss']
})
export class ContextualMenuComponent implements OnInit {
	@ViewChild('nav') public navRef: ElementRef;
	@Input() public elements: any;
	checkClassIsAdded: any;
	checkRedoClassIsAdded: any;

	@HostListener('mouseover', ['$event'])
	public mouseouver(): void {
		this.setInactive(this.elements);
	}

	@HostListener('document:keyup', ['$event'])
	public keyUp(event: KeyboardEvent): void {
		if (event.key === 'Delete') {
			this.commonService.selectedRightClickOption(event, 'Delete');
		}

		// if (!this.visible) return;

		// event.preventDefault();

		// const activeItem = this.elements.findIndex((e: any) => e.active);
		// if (event.key === 'ArrowDown') {
		// 	if (activeItem < 0) this.elements[0].active = true;
		// 	this.elements[activeItem].active = false;

		// 	const isLast = activeItem === this.elements.length - 1;
		// 	this.elements[isLast ? 0 : activeItem + 1].active = true;
		// } else if (event.key === 'ArrowUp') {
		// 	if (activeItem < 0) this.elements[0].active = true;
		// 	this.elements[activeItem].active = false;

		// 	const isFirst = activeItem === 0;
		// 	this.elements[isFirst ? this.elements.length - 1 : activeItem - 1].active = true;
		// } else if (event.key === 'ArrowRight') {
		// 	if (activeItem < 0) return;
		// 	this.elements[activeItem].visible = true;
		// 	this.elements[activeItem].submenu[0].active = true;
		// } else if (event.key === 'Enter') {
		// 	if (activeItem < 0 || !this.elements[activeItem].action) return;
		// 	this.elements[activeItem].action();
		// 	this.elements.forEach((e: any) => this.hide(e))
		// } else if (event.key === 'Escape') {
		// 	this.elements.forEach((e: any) => this.hide(e))
		// }

		// ArrowDown
		// ArrowUp
		// ArrowLeft
		// ArrowRight
		// Enter
	}

	@HostListener('document:click', ['$event'])
	public documentClick(): void {
		// console.log('contextmenu', event);
		this.elements.forEach((e: any) => this.hide(e))
	}

	@HostListener('document:contextmenu', ['$event'])
	public documentRClick(event: MouseEvent): void {
		if (!this.elements || !this.navRef) return;

		let isLeft = true;
		let isTop = true;

		// const x = event.pageX;
		// const y = event.pageY;

		const x = event.offsetX;
		const y = event.offsetY;

		let ctrlSelectable: any = document.getElementById('ctrlSelectable');
		let allImagesWidth: any = 0;
		let allImagesHeight: any = 0;
		if (ctrlSelectable) {
			allImagesWidth = ctrlSelectable.offsetWidth;
			allImagesHeight = ctrlSelectable.offsetHeight;
		}

		let clickedImgControlId: any = event.target;
		let selectedImg: any = document.getElementById('imgControl_' + clickedImgControlId.id);
		let tempHeight: any = 0;
		if (selectedImg) {
			let documentImages: any = document.querySelectorAll('.img-control');
			if (documentImages && documentImages.length > 0) {
				Array.from(documentImages).forEach((ele: any, index: any) => {
					if (index > 0 && index < parseInt(clickedImgControlId.id)) {
						let eleTemp: any = document.getElementById(ele.id);
						tempHeight += eleTemp.offsetHeight + 8;
					}
				})
			}

			//console.log('tempHeight', tempHeight);
		}

		const nav = this.navRef.nativeElement;

		this.top = `${tempHeight + y}px`;
		this.left = `${x}px`;

		if (this.controlInfoObj.totalControls > 0) {
			this.visible = true;
			let pasteBtnDisabled: any = document.getElementById('Paste_Btn');
			// let selecteditemsLength:any = localStorage.getItem('selecteditemsLength');
			let selecteditemsLength: any = this.sessionStorageService.getSelecteditemsLength();
			// let isCopiedSelecteditems:any = localStorage.getItem('isCopiedSelecteditems');
			let isCopiedSelecteditems: any = this.sessionStorageService.getIsCopiedSelecteditems();
			if ((selecteditemsLength && parseInt(selecteditemsLength) === 0) || (isCopiedSelecteditems === 'false' || isCopiedSelecteditems === false)) {
				if (pasteBtnDisabled) {
					pasteBtnDisabled.classList.add('disabled3');
				}
			}
		} else {
			this.visible = false;
		}

		if (nav.offsetWidth + x > allImagesWidth) {
			this.left = `${x - nav.offsetWidth}px`;
			isLeft = false;
		}

		if (nav.offsetHeight + y > allImagesHeight) {
			this.top = `${y - nav.offsetHeight}px`;
			isTop = false;
		}

		//animation
		if (isTop) {
			nav.style.transformOrigin = isLeft ? 'top left' : 'top right';
		} else {
			nav.style.transformOrigin = isLeft ? 'bottom left' : 'bottom right';
		}
	}

	@HostListener('document:keydown', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) {
		if (event.ctrlKey && event.key.toLowerCase() === 'd') {
			// Handle Ctrl+D shortcut here
			//console.log('Ctrl+D pressed');
			event.preventDefault();
			this.commonService.selectedRightClickOption(event, 'Duplicate');
		}
		if (event.ctrlKey && event.key.toLowerCase() === 'y') { //Redo
			// Handle Ctrl+Y shortcut here
			this.commonService.selectedRightClickOption(event, 'Redo');
		}
		if (event.ctrlKey && event.key.toLowerCase() === 'z') { //Undo
			// Handle Ctrl+Z shortcut here
			this.commonService.selectedRightClickOption(event, 'Undo');
		}
		if (event.ctrlKey && event.key.toLowerCase() === 'c') { //Copy
			// Handle Ctrl+C shortcut here
			this.commonService.selectedRightClickOption(event, 'Copy');
		}
		if (event.ctrlKey && event.key.toLowerCase() === 'v') { //Paste
			// Handle Ctrl+V shortcut here
			let selectedItems: any = this.commonService.getSelectedItems();
			let pasteBtnDisabled: any = document.getElementById('Paste_Btn');
			// console.log('selectedItems menu', selectedItems);
			if (selectedItems && selectedItems.length === 0) {
				if (pasteBtnDisabled) {
					pasteBtnDisabled.classList.add('disabled3');
				}
			} else {
				this.commonService.selectedRightClickOption(event, 'Paste');
			}


		}
	}

	visible = false;
	top = '0';
	left = '0';
	iconsArr: any[] = ['content_copy', 'content_paste', 'content_cut', 'content_copy', 'clear']; //'undo', 'redo',
	keysArr: any[] = [' Ctrl+C ', ' Ctrl+V', ' Ctrl+D', 'Delete']; //' Ctrl+X', 'Ctrl+Z', ' Ctrl+Y ',
	// fontIconArr: any[] = ['fa-undo', 'fa-repeat', 'fa-clone font-w600', 'fa-clipboard', 'fa-scissors font-w600 rotate270', 'fa-files-o font-w600', 'fa-trash-o font-16'];
	imagesArr: any[] = [
		// { path: '../../../assets/images/icons/KeyboardIcons/KeyBoard_Shortcuts_Undo.svg', name:'undo', id: 1 },
		// { path: '../../../assets/images/icons/KeyboardIcons/KeyBoard_Shortcuts_Redo.svg', name:'redo',  id: 2 },
		{ path: '../../../assets/images/icons/KeyboardIcons/KeyBoard_Shortcuts_Copy.svg', name: 'copy', id: 3 },
		{ path: '../../../assets/images/icons/KeyboardIcons/KeyBoard_Shortcuts_Paste.svg', name: 'paste', id: 4 },
		{ path: '../../../assets/images/icons/KeyboardIcons/KeyBoard_Shortcuts_Cut.svg', name: 'cut', id: 5 },
		{ path: '../../../assets/images/icons/KeyboardIcons/KeyBoard_Shortcuts_Duplicate.svg', name: 'duplicate', id: 6 },
		{ path: '../../../assets/images/icons/KeyboardIcons/KeyBoard_Shortcuts_Delete.svg', name: 'delete', id: 7 },
	];

	@ViewChild('context') context!: ElementRef;
	controlInfoObj: any = {};
	private ngUnsubscribe = new Subject();

	constructor(
		private commonService: CommonService,
		private sessionStorageService: SessionStorageService
	) {
		this.commonService.previewObject$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((updatedObject) => {
				// Handle the updated object
				this.controlInfoObj = updatedObject;
			});
	}

	ngOnInit() {

	}

	onSelectElement(event: any, ele: any) {
		if (ele.label === 'Delete' || ele.label === 'Duplicate' || ele.label === 'Undo' || ele.label === 'Redo' ||
			ele.label === 'Copy' || ele.label === 'Paste') {
			this.commonService.selectedRightClickOption(event, ele.label);
		}
		this.hide(ele);
	}

	show(elements: ContextualMenu[] | null) {
		// Called by directive
		//this.visible = true;
		this.showHideContextMenu();
		if (elements) this.elements = elements;
	}

	hide(element: ContextualMenu) {
		element.visible = false;

		// if(element && element.submenu) {
		//   element.submenu.forEach(e => this.hide(e));
		// }

		this.visible = false;
	}

	setInactive(elements: ContextualMenu[]) {
		elements.forEach(element => {
			element.active = false;
			// if(!element.submenu) return;
			// this.setInactive(element.submenu);
		})
	}

	showHideContextMenu() {
		// let isEnvelopeEditable:any = localStorage.getItem("isEnvelopeEditable");
		let isEnvelopeEditable: any = this.sessionStorageService.getIsEnvelopeEditable();
		if (isEnvelopeEditable == "true") this.visible = true;
		else this.visible = false;
	}

	isDisableRedoUndoButtons() {
		this.checkClassIsAdded = document.getElementById('undoBtn');
		if (this.checkClassIsAdded && this.checkClassIsAdded.classList) {
			document.getElementById('Undo_Btn')?.classList.remove('disabledUndoAndRedoLabel');
			if (this.checkClassIsAdded.classList.contains('disabledCtrl')) {
				document.getElementById('Undo_Btn')?.classList.add('disabledUndoAndRedoLabel');
			}
		}
		this.checkRedoClassIsAdded = document.getElementById('redoBtn');
		if (this.checkRedoClassIsAdded && this.checkRedoClassIsAdded.classList) {
			document.getElementById('Redo_Btn')?.classList.remove('disabledUndoAndRedoLabel');
			if (this.checkRedoClassIsAdded.classList.contains('disabledCtrl')) {
				document.getElementById('Redo_Btn')?.classList.add('disabledUndoAndRedoLabel');
			}
		}
	}

}
