import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { APIService } from './api.service';
import { ApiUrlConfig } from '../constants/common-apiurls';
import { AuthService } from './auth.service';
import { Translations } from '../constants/translations';
import { SessionStorageService } from './session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  laungaueTransalation = [];
  translationsConstants = Translations;
  constructor(private router: Router, private apiService: APIService, private authService: AuthService,
    private sessionStorageService: SessionStorageService) { }

  getLanguageTranslationVal(resourceKeyId: any) {
    if (this.laungaueTransalation != null && this.laungaueTransalation.length > 0) {
      return this.laungaueTransalation[resourceKeyId.toLowerCase()];
    }
    else {
      let sessionLanguageTransalationTranslation: any = sessionStorage.getItem("LanguageTransalation");
      if (sessionLanguageTransalationTranslation != null) {
        this.laungaueTransalation = JSON.parse(sessionLanguageTransalationTranslation);
        if (this.laungaueTransalation != null) {
          return this.laungaueTransalation[resourceKeyId.toLowerCase()];
        }
        else return '';
      }
      else {
        return this.getLanguageTranslations(resourceKeyId);
      }
    }

    // return this.translationsConstants.TranslationsObj[resourceKeyId.toLowerCase()];
  }

  getLanguageTranslations(resourceKeyId: any) {
    // let getIntializePrepareDetails: any = this.sessionStorageService.getIntializePrepareDetails();
    // if (this.authService.isTokenExists() == "false") this.commonService.redirectToMainApp(getIntializePrepareDetails.SourceURL);
    // else {
      if (sessionStorage.getItem('LanguageTransalation') == null || sessionStorage.getItem('LaungaueTransalation') == undefined) {
        let culture: any = "en-us";
        let userDetails: any = this.authService.getUserDetails();
        if (userDetails && userDetails != '' && userDetails != null && userDetails.languageCode != '' && userDetails.languageCode != null)
          culture = userDetails.languageCode;

        let getLanguageTranslationsUrl = ApiUrlConfig.GetLanguageTranslationsUrl + "?languageCode=" + culture;
        this.apiService.get(getLanguageTranslationsUrl).subscribe(
          (resp: any) => {
            if (resp && resp.success == 200) {
              if (resp.languageKeyDetails != null) {
                this.authService.setLanguageKeyTranslations(resp.languageKeyDetails);
                return resp.languageKeyDetails[resourceKeyId.toLowerCase()];
              }
            }
          })
      }
    //}
  }

}
