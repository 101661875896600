<div #box class="resizable-draggable control-tooltip {{ctrlObj.className | lowercase}}"
    [ngClass]="[ isActive ? 'control-active' : '']" [style.width.px]="width" [style.height.px]="height"
    [ngStyle]="{ 'pointer-events': isEnvelopeEditable == true ? 'move' : 'move' }"
    [style.left.px]="left"
    [style.top.px]="top"
    [class.active]="status === 1 || status === 2" (mousedown)="setStatus($event, 2)" (mouseup)="setStatus($event, 0)"
    (mousemove)="onMouseMove($event)" (mouseover)="onMouseOver()" [style.minWidth.px]="minWidth"
    [style.minHeight.px]="minHeight" style="min-height:10px;font-size: 12px;position: absolute;"
    [style.background]="ctrlObj.bgColor" [style.outline]="ctrlObj.outlineColor" id="resizable_{{ctrlObj.id}}"
    [attr.title]="controlTitle" [attr.data-rcptid]="ctrlObj.recipientId"
    [attr.data-original-rcptid]="ctrlObj.recipientId" [attr.data-guid]="ctrlObj.dataGuid"
    [attr.data-recp-color]="ctrlObj.recpColor" [attr.pageno]="ctrlObj.pageNo"
    [attr.data-defaultdate]="ctrlObj.defaultDate" [attr.data-selected]="ctrlObj.isRequired"
    [attr.data-typography]='ctrlObj.typography' [attr.data-placeholder]='ctrlObj.placeHolderText'
    (click)="onClickDroppedControl($event, ctrlObj)" [attr.data-original-title]="controlTitle">
    <ng-container *ngIf="showDepencieIcons">
        <div class="bottom-tooltip" id="mouseTarget">
            <div class="d-flex align-items-center only-show-on-hover">
                <div *ngIf="ctrlObj.controlName !== 'Label' && ctrlObj.controlName.toLowerCase() !== 'hyperlink'"
                    class="dropdown ps-1" style=" width: 150px;">
                    <button class="form-select custme-p text-start d-flex align-items-center" style="
                    box-shadow: none;border: none;
                    font-size: 14px; border-radius: 3px;
                    height: 29px;" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="material-icons me-1 square-select" aria-hidden="true"
                            [ngStyle]="{ color: selectedRecipentColor }">square</i>
                        <span class="font-recipent" title="{{ selectedRecipentName }}"> {{ selectedRecipentName
                            }}</span>
                    </button>
                    <ul class="dropdown-menu recpientlist">
                        <li *ngFor="let recipient of receipentListArr;let i = index;" (click)="
                            onRecipentSelect(
                                $event,
                                recipient,
                                receipentColorArr[i],
                                ctrlObj.id
                            )
                            ">
                            <a id="recipient_{{recipient.id}}" class="dropdown-item pointer recipientDeatilsListItem"
                                style="font-size: 14px;">
                                <!-- [ngStyle]="{'background-color':selectedRecipentName == recipient.name ? 'green': '', 'color':selectedRecipentName == recipient.name ? 'white': ''}" -->
                                <span class="material-icons square-font" aria-hidden="true"
                                    [ngStyle]="{ color: receipentColorArr[i] }">square</span>
                                <span class="text-wrap">{{ recipient.name }}</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <span *ngIf="ctrlObj.controlName !== 'Label' && ctrlObj.controlName.toLowerCase() !== 'hyperlink'"
                    class="br-nav me-2 ms-1">|</span>

                <span
                    *ngIf="showUpdateSignerEditIcon == true && ctrlObj.controlName !== 'Label' && ctrlObj.controlName.toLowerCase() !== 'hyperlink'"
                    class="material-icons tooltip-icon" title="Update signer"
                    (click)="updateSignerMultipleControls($event)"> edit </span>
                <span
                    *ngIf="showUpdateSignerEditIcon == true && ctrlObj.controlName !== 'Label' && ctrlObj.controlName.toLowerCase() !== 'hyperlink'"
                    class="br-nav ms-2 me-2">|</span>

                <span class="material-icons tooltip-icon me-0" title="Duplicate this control" style="margin-top: 3px;"
                    (click)="createDuplicateControl($event,ctrlObj)"> library_add </span>

                <span class="d-flex align-items-center" [ngClass]="showHideDependenciesIconDisplay"
                    *ngIf="ctrlObj.controlName === 'Text' || ctrlObj.controlName === 'Checkbox' || ctrlObj.controlName === 'Radio' || ctrlObj.controlName === 'Dropdown'">
                    <span class="br-nav ms-2 me-1">|</span>
                    <i aria-hidden="true"
                        *ngIf="ctrlObj.controlName === 'Text' || ctrlObj.controlName === 'Radio' || ctrlObj.controlName === 'Dropdown'"
                        title="Add controlling field to the document and add dependencies." style="font-size: 20px;"
                        [ngClass]="disableDependencyIconsClass" (click)="openDependencyPopup($event, ctrlObj)"></i>
                    <i id="dependency_{{ctrlObj.id}}" aria-hidden="true" *ngIf="ctrlObj.controlName === 'Checkbox'"
                        title="Add controlling field to the document and add dependencies." style="font-size: 20px;"
                        [ngClass]="disableDependencyIconsClass" (click)="openDependencyPopup($event, ctrlObj)"></i>
                </span>
                <span class="br-nav ms-2 me-1">|</span>
                <span class="material-icons tooltip-icon me-1" title="delete control"
                    (click)="deleteControl($event)">delete</span>
            </div>
            <div class="box overlay"></div>
        </div>
    </ng-container>

    <div style="overflow: hidden;">
        <div id="div{{ctrlObj.id}}" *ngIf="ctrlObj.controlName !== 'Radio' && ctrlObj.controlName !== 'Checkbox'"
            class="resize-action {{ctrlObj.className | lowercase}}" (mousedown)="setStatus($event, 1)"
            style="font-size: 12px;">
        </div>

        <div id="hyper_{{ctrlObj.id}}" *ngIf="ctrlObj.controlName && ctrlObj.controlName.toLowerCase() === 'hyperlink'"
            style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;display: block !important;">
            <span id="spn{{ctrlObj.id}}" style="position: relative;font-family: Courier;
            font-weight: normal;
            text-decoration: none;
            font-style: normal;letter-spacing: 1px;
            font-size: 12px;
            line-height: 12px;padding-top: 4px;padding-left: 4px;
            color: rgb(0, 0, 0);">{{ctrlObj.placeHolderText}}</span>
        </div>

        <!-- Date & Time Stamp, Title and Email -->
        <div *ngIf="ctrlObj.controlName === 'DateTimeStamp' || ctrlObj.controlName === 'Title' || ctrlObj.controlName === 'Email'"
            style="display: grid;">
            <span id="spn{{ctrlObj.id}}" [style.fontFamily]="ctrlObj.textFormatting.fontFamily"
                [style.fontStyle]="ctrlObj.textFormatting.fontStyle" [style.fontSize]="ctrlObj.textFormatting.fontSize"
                [style.color]="ctrlObj.textFormatting.color"
                [style.textDecoration]="ctrlObj.textFormatting.textDecoration"
                [style.fontWeight]="ctrlObj.textFormatting.fontWeight"
                [style.textAlign]="ctrlObj.textFormatting.textAlign"
                [style.lineHeight]="ctrlObj.textFormatting.lineHeight" style="position: relative;letter-spacing: 1px;
                text-wrap: nowrap;padding-top: 4px;padding-left: 2px;">{{ctrlObj.placeHolderText}}</span>
        </div>

        <div *ngIf="ctrlObj.controlName === 'Text'" style="display: grid;">
            <span id="spn{{ctrlObj.id}}" [style.fontFamily]="ctrlObj.textFormatting.fontFamily"
                [style.fontStyle]="ctrlObj.textFormatting.fontStyle" [style.fontSize]="ctrlObj.textFormatting.fontSize"
                [style.color]="ctrlObj.textFormatting.color"
                [style.textDecoration]="ctrlObj.textFormatting.textDecoration"
                [style.fontWeight]="ctrlObj.textFormatting.fontWeight"
                [style.textAlign]="ctrlObj.textFormatting.textAlign"
                [style.lineHeight]="ctrlObj.textFormatting.lineHeight" style="position: relative;
            letter-spacing: 1px;
            padding-top: 4px;padding-left: 2px;">{{ctrlObj.placeHolderText}}</span>
        </div>

        <div *ngIf="ctrlObj.controlName === 'Company'" style="display: grid;">
            <span id="spn{{ctrlObj.id}}" [style.fontFamily]="ctrlObj.textFormatting.fontFamily"
                [style.fontStyle]="ctrlObj.textFormatting.fontStyle" [style.fontSize]="ctrlObj.textFormatting.fontSize"
                [style.color]="ctrlObj.textFormatting.color"
                [style.textDecoration]="ctrlObj.textFormatting.textDecoration"
                [style.fontWeight]="ctrlObj.textFormatting.fontWeight"
                [style.textAlign]="ctrlObj.textFormatting.textAlign"
                [style.lineHeight]="ctrlObj.textFormatting.lineHeight" style="position: relative;
            letter-spacing: 1px;
            padding-top: 4px;padding-left: 2px;">{{ctrlObj.placeHolderText}}</span>
        </div>

        <div *ngIf="ctrlObj.controlName == 'Date'" style="display: grid;">
            <span id="spn{{ctrlObj.id}}" [style.fontFamily]="ctrlObj.textFormatting.fontFamily"
                [style.fontStyle]="ctrlObj.textFormatting.fontStyle" [style.fontSize]="ctrlObj.textFormatting.fontSize"
                [style.color]="ctrlObj.textFormatting.color"
                [style.textDecoration]="ctrlObj.textFormatting.textDecoration"
                [style.fontWeight]="ctrlObj.textFormatting.fontWeight"
                [style.textAlign]="ctrlObj.textFormatting.textAlign" style="position: relative;letter-spacing: 1px;
                 padding-top: 2px;padding-left: 2px;text-wrap: nowrap;">{{ctrlObj.controlDateFormat}}</span>
        </div>

        <div *ngIf="ctrlObj.controlName === 'Name'" style="display: grid;">
            <!-- <span class="requried">*</span> -->
            <span id="spn{{ctrlObj.id}}" [style.fontFamily]="ctrlObj.textFormatting.fontFamily"
                [style.fontStyle]="ctrlObj.textFormatting.fontStyle" [style.fontSize]="ctrlObj.textFormatting.fontSize"
                [style.color]="ctrlObj.textFormatting.color"
                [style.textDecoration]="ctrlObj.textFormatting.textDecoration"
                [style.fontWeight]="ctrlObj.textFormatting.fontWeight"
                [style.textAlign]="ctrlObj.textFormatting.textAlign"
                [style.lineHeight]="ctrlObj.textFormatting.lineHeight"
                style="position: relative;letter-spacing: 1px;padding-top:4px;padding-left: 2px;">{{ctrlObj.placeHolderText}}</span>
        </div>

        <div *ngIf="ctrlObj.controlName === 'Label'" style="display: grid;">
            <span id="spn{{ctrlObj.id}}" [style.fontFamily]="ctrlObj.textFormatting.fontFamily"
                [style.fontStyle]="ctrlObj.textFormatting.fontStyle" [style.fontSize]="ctrlObj.textFormatting.fontSize"
                [style.color]="ctrlObj.textFormatting.color"
                [style.textDecoration]="ctrlObj.textFormatting.textDecoration"
                [style.fontWeight]="ctrlObj.textFormatting.fontWeight"
                [style.textAlign]="ctrlObj.textFormatting.textAlign"
                [style.lineHeight]="ctrlObj.textFormatting.lineHeight" style="position: relative;letter-spacing: 1px;
                padding-top: 4px;padding-left: 2px;">{{ctrlObj.placeHolderText}}</span>
        </div>

        <span *ngIf="ctrlObj.controlName === 'Signature'" id="spn{{ctrlObj.id}}" class="set-default-signature"
            [style.color]="ctrlObj.textFormatting.color"></span>

        <span *ngIf="ctrlObj.controlName === 'NewInitials'" id="spn{{ctrlObj.id}}" class="set-default-initials"
            [style.color]="ctrlObj.textFormatting.color"></span>

        <input id="spn{{ctrlObj.id}}" *ngIf="ctrlObj.controlName === 'Radio'" type="radio" class="radio-dependencie"
            style="appearance:none;border-radius:50%; background-color: rgb(255 255 255); border: 1px solid; place-self: center;width: 18px;height: 18px;margin: 2px;" />

        <input id="spn{{ctrlObj.id}}" *ngIf="ctrlObj.controlName === 'Checkbox'" type="checkbox"
            class="checkbox-dependencie checkbox-bgcolor checkbox-hover"
            style="appearance:none; background-color: rgb(255 255 255);border:1px solid #111; place-self: center;width: 18px;height: 18px;margin: 2px;" />

        <div *ngIf="ctrlObj.controlName === 'Dropdown'" class="resize-action" (mousedown)="setStatus($event, 1)">
            <select [style.width.px]="width" [style.minWidth.px]="minWidth"
                style="border:none;letter-spacing: 1px;padding-right:2px;font-size: 14px;font-family: Courier; float:right;"
                id="spn{{ctrlObj.id}}">
            </select>
        </div>
    </div>
</div>