<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title font-w600" id="staticBackdropLabel">{{getLanguageTranslationValue(resourceKeys.lang_confirmation)}}</h5>
        <div class="modalposition" aria-label="Close">
            <span class="material-icons model-closeicon" (click)="modalRef.hide()">close</span>
        </div>
    </div>
    <div class="modal-body">
        <p class="mb-1 mt-1 font16 d-flex align-items-start flex-column">
            <label class="text-left d-flex align-items-start ms-1">
                    There are unsaved changes on this page. Do you want to save the changes before going to the previous step?</label>
        </p>
        <p class="d-flex justify-content-start mb-1 ms-1">
            <label class="mt-3 d-flex align-items-center">
                <span class="font-w600" style="color: #000;">{{getLanguageTranslationValue(resourceKeys.No)}}</span>
                <span class="material-icons ms-1 ps-1"> chevron_right</span>Goes to the previous step without saving changes.</label>
        </p>
        <p class="d-flex justify-content-start ms-1">
            <label class=" d-flex">
                <span class="font-w600" style="color: #3ab16d;">{{getLanguageTranslationValue(resourceKeys.Yes)}}</span>
                <span class="material-icons ms-1"> chevron_right</span>{{yesMessage}}</label>
        </p>
    </div>
    <div class="modal-footer justify-content-end">
        <button class="btn back-btn cancelbtn form-btn" (click)="onGotoStepOne('0')"> {{getLanguageTranslationValue(resourceKeys.No)}}</button>
        <button role="button" class="okbtn btn back-btn form-btn ms-3" (click)="onGotoStepOne('1')"> {{getLanguageTranslationValue(resourceKeys.Yes)}}</button>
    </div>
</div>
