<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title font-w600" id="staticBackdropLabel">
            {{getLanguageTranslationValue(resourceKeys.Keyboardshortcuts)}}
        </h5>
        <div class="modalposition" aria-label="Close">
            <span class="material-icons model-closeicon" (click)="modalRef.hide()">close</span>
        </div>
    </div>
    <div class="modal-body">
        <p class="mb-1 font15">
            {{getLanguageTranslationValue(resourceKeys.UseKeyboardshortcuts)}}
            <!-- Shortcuts can help you quickly perform common actions. -->
        </p>

        <div class="d-flex align-items-center justify-content-between">
            <div class="mr-2 w-50">
                <table class="w-100">
                    <tr class="controlshadow">
                        <td style="width: 80%;">
                            <div class="d-flex align-items-center ms-2">
                                <img src="../../../assets/images/icons/KeyboardIcons/KeyBoard_Shortcuts_Copy.svg"
                                    class="w-20p pointer me-2 cursor-default" />
                                <span>{{getLanguageTranslationValue(resourceKeys.Copy)}}</span>
                            </div>
                        </td>
                        <td style="width: 20%; text-align: center;">
                            <span class="pe-2"> Ctrl+C </span>
                        </td>
                    </tr>

                    <tr class="controlshadow">
                        <td style="width: 80%;">
                            <div class="d-flex align-items-center ms-2">
                                <img src="../../../assets/images/icons/KeyboardIcons/KeyBoard_Shortcuts_Paste.svg"
                                    class="w-20p pointer me-2 cursor-default" />
                                <span>{{getLanguageTranslationValue(resourceKeys.Paste)}}</span>
                            </div>
                        </td>
                        <td style="width: 20%; text-align: center;">
                            <span class="pe-2"> Ctrl+V </span>
                        </td>
                    </tr>
                    <tr class="controlshadow opacity">
                        <td style="width: 80%;">
                            <div class="d-flex align-items-center ms-2">
                                <img src="../../../assets/images/icons/KeyboardIcons/KeyBoard_Shortcuts_Cut.svg"
                                    class="w-20p pointer me-2 rotate270 cursor-default" />
                                <span>{{getLanguageTranslationValue(resourceKeys.Cut)}}</span>
                            </div>
                        </td>
                        <td style="width: 20%; text-align: center;">
                            <span class="pe-2"> Ctrl+X </span>
                        </td>
                    </tr>
                    <tr class="controlshadow">
                        <td style="width: 80%;">
                            <div class="d-flex align-items-center ms-2">
                                <img src="../../../assets/images/icons/KeyboardIcons/KeyBoard_Shortcuts_Delete.svg"
                                    class="w-20p pointer me-2 cursor-default" />
                                <span>{{getLanguageTranslationValue(resourceKeys.Delete)}}</span>
                            </div>
                        </td>
                        <td style="width: 20%; text-align: center;">
                            <span class="pe-2"> Delete </span>
                        </td>
                    </tr>
                </table>
            </div>

            <div class="ms-2 w-50">
                <table class="w-100">
                    <tr class="controlshadow">
                        <td style="width: 80%;">
                            <div class="d-flex align-items-center ms-2">
                                <img src="../../../assets/images/icons/KeyboardIcons/KeyBoard_Shortcuts_Duplicate.svg"
                                    class="w-20p pointer me-2 cursor-default" />
                                <!-- <i class="fa fa-files-o icon-font me-2" aria-hidden="true"></i> -->
                                <span>{{getLanguageTranslationValue(resourceKeys.Duplicate)}}</span>
                            </div>
                        </td>
                        <td style="width: 20%; text-align: center;">
                            <span class="pe-2"> Ctrl+D </span>
                        </td>
                    </tr>

                    <tr class="controlshadow">
                        <td style="width: 80%;">
                            <div class="d-flex align-items-center ms-2">
                                <!-- <span class="fa fa-undo font-1rem me-2">  </span> -->
                                <img src="../../../assets/images/icons/KeyboardIcons/KeyBoard_Shortcuts_Undo.svg"
                                    id="undoBtn" class="w-18p pointer me-2 cursor-default" />
                                <span>{{getLanguageTranslationValue(resourceKeys.Undo)}}</span>
                            </div>
                        </td>
                        <td style="width: 20%; text-align: center;">
                            <span class="pe-2"> Ctrl+Z </span>
                        </td>
                    </tr>

                    <tr class="controlshadow">
                        <td style="width: 80%;">
                            <div class="d-flex align-items-center ms-2">
                                <!-- <span class="fa fa-repeat font-1rem me-2"> </span> -->
                                <img src="../../../assets/images/icons/KeyboardIcons/KeyBoard_Shortcuts_Redo.svg"
                                    id="redoBtn" class="w-18p pointer me-2 cursor-default" />
                                <span>{{getLanguageTranslationValue(resourceKeys.Redo)}}</span>
                            </div>
                        </td>
                        <td style="width: 20%; text-align: center">
                            <span class="pe-2"> Ctrl+Y </span>
                        </td>
                    </tr>

                    <tr class="controlshadow opacity">
                        <td style="width: 80%;">
                            <div class="d-flex align-items-center ms-2">
                                <!-- <i class="fa fa-globe icon-font me-2" aria-hidden="true"></i> -->
                                <img src="../../../assets/images/icons/KeyboardIcons/KeyBoard_Shortcuts_Select All.svg"
                                    class="w-18p pointer me-2 cursor-default" />
                                <span>Select all</span>
                            </div>
                        </td>
                        <td style="width: 20%; text-align: center;">
                            <span class="pe-2"> Ctrl+A </span>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer justify-content-end">
        <!-- <button class="btn back-btn cancelbtn d-flex align-items-center" data-bs-dismiss="modal"> Cancel</button> -->
        <button role="button" class="okbtn btn back-btn form-btn ms-3" (click)="onDone()">
            Done
        </button>
    </div>
</div>