import { Component, OnInit } from '@angular/core';
import { CommonConstants } from '../../constants/common-constants';
import { ApiUrlConfig, NavigateUrlConfig } from 'src/app/constants/common-apiurls';
import { APIService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { Subject, takeUntil } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { ResourceKeys } from 'src/app/constants/resourcekeys';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
	userName: any = '';
	commonConstants = CommonConstants;
	resourceKeys = ResourceKeys;
	HomeUrl: string = '';
	SendUrl: string = '';
	EnvelopeUrl: string = '';
	TemplateUrl: string = '';
	SettingsUrl: string = '';
	TranslationsUrl: string = '';
	UserDetailsUrl: string = '';
	StatsUrl: string = '';
	showLoader: boolean = false;
	disableNavigationBar: any = 'false';
	private ngUnsubscribeNavBtns = new Subject<void>();
	private ngUpdateHeaderSubject = new Subject<void>();
	displayOutOfOfficeLabel: boolean = false;
	rsignUrl: any = environment.rsignUrl;
	isLanguageTranslator: boolean = false;
	displayStats: boolean = false;

	constructor(
		private apiService: APIService,
		private authService: AuthService,
		private commonService: CommonService,
		private sessionStorageService: SessionStorageService
	) {
		this.commonService.getDisableNavBtns$.pipe(takeUntil(this.ngUnsubscribeNavBtns)).subscribe((disableButtonStr: any) => {
			if (disableButtonStr && disableButtonStr != '' && disableButtonStr != null) {
				let splitDisableButtonArr: any = JSON.parse(disableButtonStr);
				if (splitDisableButtonArr) {
					if (splitDisableButtonArr.DisableNavigationBar == true) {
						this.disableNavigationBar = 'true';
					}
				}
			}
		});

		this.commonService.updateHeaderSubject$.pipe(takeUntil(this.ngUpdateHeaderSubject)).subscribe((data: any) => {
			this.updateHeader();
		});
	}

	ngOnInit(): void {
		this.updateHeader();

		// let disableButtonStr: any = localStorage.getItem("DisableStr");
		let disableButtonStr: any = this.sessionStorageService.getDisableTabOptions();
		if (disableButtonStr && disableButtonStr != '' && disableButtonStr != null) {
			let splitDisableButtonArr: any = JSON.parse(disableButtonStr);
			if (splitDisableButtonArr) {
				if (splitDisableButtonArr.DisableNavigationBar == true) this.disableNavigationBar = 'true';
			}
		}

		let getIntializePrepareDetails: any = this.sessionStorageService.getIntializePrepareDetails();
		// let prepareType: any = localStorage.getItem('prepareType');
		let prepareType: any = getIntializePrepareDetails.PrepareType;
		let sendNavigation: any = document.getElementById('sendNav');
		let templateNavigation: any = document.getElementById('templateNav');
		if (prepareType && prepareType.toLowerCase() === 'envelope') {
			if (templateNavigation && templateNavigation.classList.contains('active')) {
				templateNavigation.classList.remove('active');
			}
			if (sendNavigation && sendNavigation.classList) {
				sendNavigation.classList.add('active');
			}
		}
		else if (prepareType && prepareType.toLowerCase() === 'template') {
			if (sendNavigation && sendNavigation.classList.contains('active')) {
				sendNavigation.classList.remove('active');
			}
			if (templateNavigation && templateNavigation.classList) {
				templateNavigation.classList.add('active');
			}
		}
	}

	updateHeader() {
		this.rsignUrl = environment.rsignUrl;
		let getIntializePrepareDetails: any = this.sessionStorageService.getIntializePrepareDetails();
		if (getIntializePrepareDetails && getIntializePrepareDetails != null && getIntializePrepareDetails.SourceUrlType && getIntializePrepareDetails.SourceUrlType != "") {
			this.rsignUrl = this.commonService.generateDomainRediRectURL(getIntializePrepareDetails.SourceUrlType);
		}
		let userInfo: any = this.authService.getUserDetails();
		if (userInfo) {
			this.isLanguageTranslator = userInfo.isLanguageTranslator;
			if (userInfo.userTypeID && userInfo.userTypeID != null && userInfo.userTypeID.toLowerCase() != "5c9bd2ac-b92b-4bdb-b6d6-2ed65fe9f420") { //user role = '5c9bd2ac-b92b-4bdb-b6d6-2ed65fe9f420'
				this.displayStats = true; //showing stats for these roles Customer Admin, RSign Support Manager, RSign Support Rep
			}
			let uname: any = '';
			if (userInfo.lastName) uname = userInfo.firstName;
			if (userInfo.lastName) uname = uname + " " + userInfo.lastName;
			this.userName = uname;
			let divFlagIcon: any = document.getElementById('divFlagIcon');
			if (userInfo.languageCode == 'en-us') divFlagIcon.classList.add('englishIcon');
			if (userInfo.languageCode == 'es-es') divFlagIcon.classList.add('spanishIcon');
			if (userInfo.languageCode == 'de-de') divFlagIcon.classList.add('germanIcon');
			if (userInfo.languageCode == 'fr-fr') divFlagIcon.classList.add('frenchIcon');
			if (userInfo.languageCode == 'pt-pt') divFlagIcon.classList.add('portugueseIcon');
			if (userInfo.languageCode == 'nl-nl') divFlagIcon.classList.add('dutchIcon');
			if (userInfo.languageCode == 'lv-lv') divFlagIcon.classList.add('latvianIcon');
			if (userInfo.languageCode == 'lt-lt') divFlagIcon.classList.add('lithuaniaIcon');
			if (userInfo.languageCode == 'pl-pl') divFlagIcon.classList.add('polishIcon');
			if (userInfo.languageCode == 'da-dk') divFlagIcon.classList.add('danishIcon');
			if (userInfo.languageCode == 'it-it') divFlagIcon.classList.add('italianIcon');
			if (userInfo.languageCode == 'hu-hu') divFlagIcon.classList.add('hungarianIcon');
			if (userInfo.languageCode == 'no-na') divFlagIcon.classList.add('norwegianIcon');
			if (userInfo.languageCode == 'ro-ro') divFlagIcon.classList.add('romanianIcon');

			if (userInfo.outOfOfficeLabel == true) this.displayOutOfOfficeLabel = true;
			if (userInfo.emailID) {
				let path: any = this.rsignUrl + 'Account/RSignDetailsNewSigners?ru=' + userInfo.emailID + "&rpk=" + this.authService.getToken();
				this.HomeUrl = path + '&redirect=Home';
				this.SendUrl = path + '&redirect=Send';
				this.EnvelopeUrl = path + '&redirect=Envelopes';
				this.TemplateUrl = path + '&redirect=Templates';
				this.SettingsUrl = path + '&redirect=Settings';
				this.TranslationsUrl = path + '&redirect=Translations';
				this.UserDetailsUrl = this.rsignUrl + NavigateUrlConfig.UserDetails;
				this.StatsUrl = path + '&redirect=Stats';
			}
			else {
				this.bindURLs();
			}
		}
		else {
			this.bindURLs();
		}
	}

	bindURLs() {
		this.HomeUrl = this.rsignUrl + NavigateUrlConfig.Home;
		this.SendUrl = this.rsignUrl + NavigateUrlConfig.Send;
		this.EnvelopeUrl = this.rsignUrl + NavigateUrlConfig.Envelope;
		this.TemplateUrl = this.rsignUrl + NavigateUrlConfig.Template;
		this.SettingsUrl = this.rsignUrl + NavigateUrlConfig.Settings;
		this.TranslationsUrl = this.rsignUrl + NavigateUrlConfig.Translations;
		this.UserDetailsUrl = this.rsignUrl + NavigateUrlConfig.UserDetails;
		this.StatsUrl = this.rsignUrl + NavigateUrlConfig.Stats;
	}

	redirectTo(type: any) {
		let rsignURL: any = this.rsignUrl;
		switch (type) {
			case "Home":
				window.location.href = rsignURL + NavigateUrlConfig.Home;
				break;
			case "Send":
				window.location.href = rsignURL + NavigateUrlConfig.Send;
				break;
			case "Envelopes":
				window.location.href = rsignURL + NavigateUrlConfig.Envelope;
				break;
			case "Templates":
				window.location.href = rsignURL + NavigateUrlConfig.Template;
				break;
			case "Settings":
				window.location.href = rsignURL + NavigateUrlConfig.Settings;
				break;
			case "Translations":
				window.location.href = rsignURL + NavigateUrlConfig.Translations;
				break;
			case "UserDetails":
				window.location.href = rsignURL + NavigateUrlConfig.UserDetails;
				break;
		}
	}

	logout() {
		this.showLoader = true;
		localStorage.clear();
		sessionStorage.clear();
		window.location.href = this.rsignUrl + NavigateUrlConfig.LogOff;
	}

	redirectCorporateURL(PageName: any, OpenType: any) {
		let getRedirectUrl = ApiUrlConfig.GetRedirectURL + "/" + PageName;
		this.apiService.get(getRedirectUrl).subscribe(
			(resp: any) => {
				if (resp && resp.StatusCode == 200) {
					if (resp.Message != null) {
						OpenType == 'self' ? window.location.href = resp.Message : window.open(resp.Message, "_blank");
					}
				}
			})
	}

	ngOnDestroy() {
		this.ngUnsubscribeNavBtns.next();
		this.ngUnsubscribeNavBtns.complete();
	}

	OutOfOfficeMode() {
		window.location.href = this.rsignUrl + NavigateUrlConfig.GetOutOfOfficeDetails;
	}

	getLanguageTranslationValue(resourcekeyId: any) {
		return this.commonService.getLabelTranslationVal(resourcekeyId);
	}
}
