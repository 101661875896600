<div *ngIf="slides.length > 0" class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title font-w600" id="staticBackdropLabel">Select Brand</h5>
        <div class="modalposition" aria-label="Close">
            <span class="material-icons model-closeicon" (click)="modalRef.hide()">close</span>
        </div>
    </div>
    <div class="modal-body">
        <div class="main-container">
            <carousel [itemsPerSlide]="itemsPerSlide" data-interval="false" [noWrap] = "true">
                <div class="radio-buttons">
                    <slide class="" *ngFor="let slide of slides; let index=index">
                        <label class="custom-radio">
                            <input id="slider{{index+1}}" type="radio" name="radio" [checked]="slide.isDefault || slide.IsDefault" />
                            <span class="radio-btn">
                                <i class="material-icons"> check_circle </i>
                                <div class="hobbies-icon img-box">
                                    <img [src]="slide.base64String || slide.Base64String" alt="Image description" alt="image" />
                                </div>
                                <div class="d-flex justify-content-center heading">
                                    <h3 class="font18" style="position: relative;
                                        color: black;
                                        font-weight: bold;
                                        width: 135px;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;">{{slide.brandName || slide.BrandName}}
                                    </h3>
                                </div>
                            </span>
                        </label>
                    </slide>
                </div>
            </carousel>
        </div>
    </div>
    <!-- end carsouls ended -->
    <div class="modal-footer justify-content-center">
        <button [disabled]="isEnvlpEdited == true" role="button" class="okbtn btn back-btn form-btn ms-3" (click)="onOk()">
            Select
        </button>
    </div>
</div>
