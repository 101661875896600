<div class="d-flex pagination-disabled" style="margin-top: -8px;">
	<ul class="paginationContainer pagination d-flex align-items-center justify-content-center "
		style="margin: 0px;">
		<li class="page-item">

		</li>
		<li class="page-item d-flex"></li>
	</ul>

	<div class="d-flex align-items-center page-details ms-2 pe-1 ">
		<input #paginationInput id="paginationInput" [value]="pageVal" autocomplete="off"
			(keyup.enter)="onPageSelection($event, 'paginationInput')"
			(focusout)="onPageSelection($event, 'paginationInput')"
			(ontouchstart)="onPageSelection($event, 'paginationInput')"
			(input)="formatInput(paginationInput);"
			(paste)="onPaste($event)"
			aria-label="Pagination"
			type="text" class="form-control bx-shadow neu-light1 res-hw"
			min="1" max="{{documentDataLength}}"
			style="cursor: auto;border-radius: 2px 0px 0px 2px !important;border-right: 1px solid #d3d2d2 !important;box-shadow:none" />

		<div class="dropdown">
			<span class="page-arrow d-flex align-items-center" id="paginationDropdownMenu" data-bs-toggle="dropdown">
				&nbsp; of &nbsp; {{documentDataLength}} <i class="material-icons">arrow_drop_down</i>
			</span>
			<ul class="dropdown-menu mt-5p droparrow page-list" aria-labelledby="paginationDropdownMenu">
				<li class="drop-border" *ngFor="let pagenum of documentDataLength | numberArray; let i = index;"
					[value]="pagenum" (click)="onPaginateChange(pagenum)">
					<a class="dropdown-item font14 mb-1" href="javascript: false;">{{pagenum}}</a>
				</li>
			</ul>
			<!-- <div class="paginationLoader spinner-border"
				[ngStyle]="{'color': 'blue', 'top': '3px','left': '34px', 'width':'23px','height':'23px'}" style="position: absolute;z-index:99;">
			</div> -->
		</div>

	</div>

	<div class="paginationLoader spinner-border"
        [ngStyle]="{'color': 'blue', 'top': '3px','left': '0px', 'width':'23px','height':'23px'}" style="position: relative;z-index:99;">
    </div>

</div>