<header class="headerFixed">
	<div class="logo-wrapper">
		<a href="javascript: false;">
			<img class="rsign-logo liNav" src="../../../assets/images/RSign.svg" alt="image" />
		</a>
		<a class="rcircle-logo" href="javascript: false;">
			<img class="liNav" src="../../../assets/images/RPost-Button-White.svg" alt="image" />
		</a>
	</div>
	<nav class="navbar navbar-default py-0 header-container" role="navigation" style="background: none; min-height:0px;">
		
	</nav>
</header>

<main class="container-login100">
    <div class="wrapperIndex">
        <div class="dvRadius">
            <div class="divMainWrapper user_card">
                <div id="formContent" class="dvglobalInfo_2">  
                    <div class="dvdevices">
                        <img src="../../../assets/images/success_info.png" style="width:70px;" />
                        <h5 class="Globalinfo_1 mt-3 mb-0 font-w600">{{displayMessage}}</h5>
                    </div>                   

                    <div>
                        <p  class="ptextAlign">
                            {{RSignMakesESignatureText}}
                        </p>
                    </div>
                </div>             

                <div class="dvglobalInfo_3">
                    <p class="pbottomeStyle">
                        <i class="fa fa-play-circle faCircleIcon"></i> &nbsp; <a class="anchorColor"
                            style="cursor: pointer;" (click)="redirectCorporateURL('RSignSignUp','self')"
                            (ontouchstart)="redirectCorporateURL('RSignSignUp','self')"> {{LearnMoreTextOnly}} </a>
                    </p>

                    <p class="pbottomeStyle">
                        <i class="fa fa-play-circle faCircleIcon"></i> &nbsp; <a class="anchorColor"
                            style="cursor: pointer;" (click)="redirectCorporateURL('FreeTrail','self')"
                            (ontouchstart)="redirectCorporateURL('FreeTrail','self')"> {{freeTrial}} </a>
                    </p>
                </div>                
            </div>
        </div>
    </div>
</main>

<footer style="background: #eee;" class="default-footer mt-auto pt-2 pb-2">
    <div style="font-size: 10px;">
        <div class="fotertext" [innerHTML]="htmlFooterString">
        </div>
    </div>
</footer>

<div *ngIf="showLoader" class="loader">
</div>
