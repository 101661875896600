import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';
import { ApiUrlConfig } from '../constants/common-apiurls';
import { ServicesError } from './services.error';
import { environment } from 'src/environments/environment';
import { SessionStorageService } from '../services/session-storage.service';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	baseUrl = environment.apiBaseUrl;

	constructor(
		private http: HttpClient,
		private error: ServicesError,
		private sessionStorageService: SessionStorageService
	) { }

	validateToken(authToken: any) {
		let userPrepareUrl: any = { 'PrepareUrl': authToken };
		const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
		return this.http.post(this.baseUrl + ApiUrlConfig.DecryptValidateTokenUrl, userPrepareUrl, { headers }).pipe(catchError(this.error.handleError));
	}

	setEnvelopeTokenDetails(authToken: string, prepareType: string, envelopeOrTemplateId: string): void {
		localStorage.setItem('Token', authToken);
		this.sessionStorageService.setIntializePrepareDetails('Token', authToken);
		this.sessionStorageService.setIntializePrepareDetails('PrepareType', prepareType);
		this.sessionStorageService.setIntializePrepareDetails('EnvelopeOrTemplateId', envelopeOrTemplateId);
	}

	setUserDetails(userInfo: any): void {
		this.sessionStorageService.setUserDetails(JSON.stringify(userInfo));
	}

	getUserDetails() {
		let userInfo: any = this.sessionStorageService.getUserDetails();
		if (userInfo) {
			return JSON.parse(userInfo);
		}
	}

	getDetails() {
		let tokendata: any = this.sessionStorageService.getIntializePrepareDetails();
		let details: any = { "token": tokendata.Token, "prepareType": tokendata.PrepareType, "envelopeOrTemplateId": tokendata.EnvelopeOrTemplateId };
		return JSON.stringify(details);
	}

	getToken() {
		let envelopeTokenDetails: any = this.sessionStorageService.getIntializePrepareDetails();
		if (envelopeTokenDetails) { return envelopeTokenDetails.Token; }
	}

	setEnvelopeOrTemplateDetails(resp: any) {
		localStorage.setItem("envelopeOrTemplateDetails", JSON.stringify(resp));
	}

	getEnvelopeOrTemplateDetails() {
		let envelopeOrTemplateDetails: any = localStorage.getItem("envelopeOrTemplateDetails");
		if (envelopeOrTemplateDetails) return envelopeOrTemplateDetails;
	}

	setRecipientDetails(recpDetails: any) {
		this.sessionStorageService.setRecipientDetails(JSON.stringify(recpDetails));
	}

	getRecipientDetails() {
		let recipientDetails: any = this.sessionStorageService.getRecipientDetails();
		if (recipientDetails) return recipientDetails;
	}

	setRecipientColorDetails(recpColorDetails: any) {
		this.sessionStorageService.setRecipientColorDetails(JSON.stringify(recpColorDetails));
	}

	getRecipientColorDetails() {
		let recpColorDetails: any = this.sessionStorageService.getRecipientColorDetails();
		if (recpColorDetails) return recpColorDetails;
	}

	setLanguageKeyTranslations(languageKeyTranslations: any): void {
		sessionStorage.setItem("LanguageTransalation", JSON.stringify(languageKeyTranslations));
	}

	isTokenExists() {
		let tokenInfo = localStorage.getItem('Token');
		if (tokenInfo && tokenInfo != "" && tokenInfo != undefined && tokenInfo != "undefined" && tokenInfo != null && tokenInfo != "null") return "true";
		else return "false";
	}
}



