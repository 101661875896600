import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ApiUrlConfig } from 'src/app/constants/common-apiurls';
import { ResourceKeys } from 'src/app/constants/resourcekeys';
import { APIService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/services/common.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-info',
    templateUrl: './info.component.html',
    styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {
    title = 'RSign | Info';
    showLoader: boolean = false;
    displayMessage: any;
    htmlFooterString: any;
    LearnMoreTextOnly: any;
    freeTrial: any;
    RSignMakesESignatureText: any;

    constructor(private titleService: Title, private apiService: APIService, private commonService: CommonService,
        private NavigationService: NavigationService,
        private sessionStorageService: SessionStorageService
    ) { }

    @HostListener('window:popstate', ['$event'])
    onPopState(event: any) {
        console.log('Back button pressed');
        this.NavigationService.reloadPage();
    }

    ngOnInit(): void {
        this.showLoader = true;
        this.titleService.setTitle(this.title);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.htmlFooterString = '<p> ' +
            '<a href="https://rpost.com/legal-notices/terms-and-conditions" target="_blank" class="footerLinks">General Terms & Legal Notices</a>: Click to view service level terms, conditions, privacy, ' +
            '<a class="footerLinks" href="https://rpost.com/legal-notices/legal-and-patent-notice" target="_blank"> patent, trademark</a>, and other service and legal notices. ' +
            '</p>';
     
        this.displayMessage = this.sessionStorageService.getDisplayMessage();
        if (this.displayMessage == "null" || this.displayMessage == null || this.displayMessage == undefined || this.displayMessage == "undefined") this.displayMessage = "";

        this.LearnMoreTextOnly = this.commonService.getLabelTranslationVal(ResourceKeys.LearnMoreTextOnly.toLowerCase());
        this.freeTrial = this.commonService.getLabelTranslationVal(ResourceKeys.freeTrial.toLowerCase());
        this.RSignMakesESignatureText = this.commonService.getLabelTranslationVal(ResourceKeys.RSignMakesESignatureText.toLowerCase());
        this.showLoader = false;
    }

    redirectCorporateURL(PageName: any, OpenType: any) {
        let getRedirectUrl = environment.corporateURLApiBaseUrl + "/" + ApiUrlConfig.GetRedirectURL + "/" + PageName;
        this.apiService.getRedirectURL(getRedirectUrl).subscribe(
            (resp: any) => {               
                if (resp && resp.StatusCode == 200) {
                    if (resp.Message != null) {                        
                        OpenType == 'self' ? this.commonService.redirect(resp.Message) : this.commonService.openInNewTab(resp.Message);
                    }
                }
            })
    }
}
